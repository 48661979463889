import React, { useState, useEffect, useRef } from "react";
import { Modal, Box } from "@mui/material";
import "./PedidosModal.scss";
import { deleteOrderTable } from "../../../components/helpers/Api";
import ModalConfirm from "../../../components/partials/modals/ModalConfirm";
import ModalPrint from "../../../components/partials/modals/ModalPrint";


const PedidosModal = ({ ...props }) => {
  const [address, setAddress] = useState("");
  const [id, setId] = useState("");
  const [codIpedidoMesa, setCodIpedidoMesa] = useState([]);
  const [codMesa, setCodMesa] = useState("");
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loadingModalConfirm, setLoadingModalConfirm] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);

  useEffect(() => {
    const formattedAddress = props?.items?.endereco?.includes("undefined")
      ? "local"
      : props?.items?.endereco;
    setAddress(formattedAddress); setId(props.items.cod_pedidomesa);
    setCodMesa(props.items.cod_mesa);
    const array = [];

    props.items?.tb_ipedidomesas?.forEach((item) => {
      array.push(item.cod_ipedidomesa);
      item.additional_value = 0;
      item.tb_ipedidomesaacoms.forEach((subItem) => {
        item.additional_value += parseFloat(subItem.valor);
      });
    });
    setCodIpedidoMesa(array);
  }, [props.items]);

  function handleDeleteOrder() {
    setLoadingModalConfirm(true);
    deleteOrderTable(id, codMesa, JSON.stringify(codIpedidoMesa))
      .then((res) => {
        if (res.status === 200) {
          props.onReloadList();
          props.setOrderModal();
          setOpenModalConfirm(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingModalConfirm(false);
      });
  }


  // const addressF = address.includes(undefined) ?"local" : address

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="order-modal">
          <div className="box-icon">
            <span>
              <i
                className="fa-solid fa-xmark"
                onClick={() => props.setOrderModal()}
              ></i>
            </span>
          </div>
          <div className="top-modal">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <p>Pedido #{props.items.numero_pedidomesa}</p>
              <p className="state">Pronto para a entrega</p>
            </div>

            <div className="d-flex">
              <p className="order-time">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15px"
                    height="15px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-clock"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                  </svg>
                </span>
                <span>
                  {new Date(props.items.createdAt).getHours() < 10
                    ? "0" + new Date(props.items.createdAt).getHours()
                    : new Date(props.items.createdAt).getHours()}
                  :
                  {new Date(props.items.createdAt).getMinutes() < 10
                    ? "0" + new Date(props.items.createdAt).getMinutes()
                    : new Date(props.items.createdAt).getMinutes()}
                </span>
              </p>
              <p className="box-icon" onClick={() => setOpenModalConfirm(true)}>
                <i className="fa-regular fa-trash-can"></i>
              </p>
              <p
                className="box-icon"
                onClick={() => props.updateOrder(props.items)}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </p>
              <button
                className="box-icon"
                onClick={() => setOpenModalPrint(true)}
              >
                <i class="fa fa-print" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div>
            <select
              name=""
              id=""
              className="form-select mb-2"
              onChange={(e) =>
                props.handleUpdateStatus(
                  e.target.value,
                  props.items.telefone_cliente,
                  props.items.cod_pedidomesa
                )
              }
            >
              <option value="">Alterar status</option>
              <option value="analisando">Em Análise</option>
              <option value="produzindo">Em Produção</option>
              <option value="pronto">Pronto Para Entrega</option>
            </select>
          </div>
          <div className="address-box">
            <p style={{ borderRight: "1px solid #838383" }}>
              <i className="fa-solid fa-house"></i>
            </p>
            <p className="address-text">{address}</p>
          </div>
          <p className="order-items-title">Itens do Pedido</p>
          <div className="item-list">
            {props.items?.tb_ipedidomesas?.map((item, index) => (
              <div className="order-list" key={index}>
                <p className="">
                  <span>
                    {item.quantidade}x {item.descricao}
                  </span>{" "}
                  <br />
                  <span className="order-observation">
                    {item.observacao.length > 0 ? "Obs:" : ""} {item.observacao}
                  </span>
                  <br />
                  {item.tb_ipedidomesaacoms.map((subItem, subIndex) => (
                    <span key={subIndex}>
                      <span className="order-observation">
                        {subItem.quantidade}x
                      </span>
                      <span className="order-observation">
                        {subItem.web_descricao}
                      </span>
                      <span
                        className="order-observation"
                        style={{ fontWeight: 650 }}
                      >
                        R$ {subItem.valor?.toFixed(2)}
                      </span>
                      <br />
                    </span>
                  ))}
                </p>
                <span style={{ fontWeight: 650 }}>
                  R$ {(item.valor_unitario - item.additional_value).toFixed(2)}
                </span>
              </div>
            ))}
          </div>
          <div className="box-sub-total">
            <p>
              <span>SubTotal</span>
              <span style={{ fontWeight: 650 }}>
                R$ {props?.items?.valor_produtos?.toFixed(2)}
              </span>
            </p>
            <p>
              <span>Taxa de Entrega</span>
              <span style={{ fontWeight: 650 }}>
                R$ {props?.items?.valor_servico?.toFixed(2)}
              </span>
            </p>
          </div>
          <div className="total-box">
            <p>Total</p>
            <p>R$ {props?.items?.valor_total?.toFixed(2)}</p>
          </div>
        </Box>
      </Modal>
      <ModalConfirm
        title="Tem certeza que deseja deletar este endereço?"
        open={openModalConfirm}
        setModalCrudFalse={() => setOpenModalConfirm(false)}
        handleRequest={() => handleDeleteOrder()}
        loading={loadingModalConfirm}
        textBack="Cancelar"
        textSubmit="Deletar"
      />
      <ModalPrint
        open={openModalPrint}
        setModalCrudFalse={() => setOpenModalPrint(false)}
        pedido={props.items}
      />
    </>
  );
};

export default PedidosModal;
