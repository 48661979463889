import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const PARCEIRO_URL = `${API_URL}/parceiro/`;

export async function getParceiros(razao_social, page, token) {

    const codOrg = localStorage.getItem("cod_org");
    const parceirosUrl = `${PARCEIRO_URL}${razao_social}?page=${page}&cod_org=${codOrg}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(parceirosUrl, { headers });
    return response.data;
}

export async function getParceiroPorEmail(email, token) {

    const parceirosUrl = `${PARCEIRO_URL}${email}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(parceirosUrl, { headers });
    return response.data;
}

export async function salvarParceiro(cod_parceiro, novoParceiro, token) {

    const headers = { 'authorization': token }

    let response;

    if (cod_parceiro) {
        response = await axios.patch(`${PARCEIRO_URL}${cod_parceiro}`, novoParceiro, { headers });
    } else {
        response = await axios.post(`${PARCEIRO_URL}`, novoParceiro, { headers });
    }

    return response.data;
}

export async function excluirParceiro(cod_parceiro, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${PARCEIRO_URL}${cod_parceiro}`, { headers });
    return response.data;
};

export async function getParceirosPreVenda(cod_org, token) {
    const headers = { 'authorization': token };
    const parceirosPrevenda = await axios.get(
        `${PARCEIRO_URL}/prevenda-parceiros/${cod_org}`,
        { headers }
    );
    return parceirosPrevenda.data
};