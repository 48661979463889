/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import "./CadMeioPagamentoModal.scss";
import { toast } from "react-toastify";
import {
  createPaymentMethod,
  updatePaymentMethod,
} from "../../../components/helpers/Api.jsx";
/**
 * props:
 * - data
 * - onSubmit
 */

function CadMeioPagamentoModal(props) {
  const [status, setStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setDescription(props.data.nome);
    setPaymentMethod(props.data.descricao);
    setStatus(props.data.status);
  }, [props.data]);

  function handleCreatePaymentMethod() {
    if (paymentMethod.length < 3) {
      return toast.error("Insira uma forma de pagamento válida");
    }
    setLoading(true);

    if (props.data.cod_forma_pgto) {
      let data = {
        descricao: paymentMethod,
        cod_org: localStorage.getItem("cod_org"),
        status: status,
      };
      updatePaymentMethod(data, props.data.cod_forma_pgto)
        .then((res) => {
          if (res.status === 201) {
            setPaymentMethod("");
            props.onSubmit();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let data = {
        descricao: paymentMethod,
        cod_org: localStorage.getItem("cod_org"),
      };
      createPaymentMethod(data)
        .then((res) => {
          if (res.status === 201) {
            setPaymentMethod("");
            props.onSubmit();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box id="box-mui-consumer" className="">
          <main className="cliente-modal">
            <section className="first-section">
              <p className="title-modal">Nova Forma de Pagamento</p>
              <i
                className="fa-solid fa-xmark"
                role="button"
                onClick={() => props.setFalse()}
              ></i>{" "}
            </section>
            <section className="second-section">
              <label className="form-control fw-bolder">
                Forma de Pagamento
                <input
                  style={{ color: "black" }}
                  className="form-control "
                  placeholder="Ex: Dinheiro"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  autoComplete="off"
                  value={paymentMethod}
                />
              </label>
              {props?.data?.cod_forma_pgto ? (
                <label className="form-control fw-bolder">
                  Status
                  <select name="" id="" className="form-select">
                    <option value={status}>
                      {status === "1" ? "Ativo" : "Inativo"}
                    </option>
                    <option value={status === "1" ? "0" : "1"}>
                      {status === "1" ? "Inativo" : "Ativo"}
                    </option>
                  </select>
                </label>
              ) : (
                ""
              )}
            </section>
            <button
              className="save"
              onClick={() => handleCreatePaymentMethod()}
            >
              {!loading && <span className="indicator-label">Salvar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </main>
        </Box>
      </Modal>
    </>
  );
}

export default CadMeioPagamentoModal;
