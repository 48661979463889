import React from "react";
import {formataData, formataValorComSimbolo} from '../../utils/utils';

/**
 * props:
 * - data
 * - onAlterarClick
 */
function PedidoCompraRow(props) {
    
    return (
        <tr>
            <td>
                {props.data.cod_pedido_compra}
            </td>
            <td>
                {formataData(props.data.data)}
            </td>
            <td>
                {props.data.numero_pedido}
            </td>
            <td>
                {props.data.razao_fornecedor}
            </td>
            <td>
                {formataValorComSimbolo(props.data.total_liquido)}
            </td>
            <td>
                {
                    <button id={"alterar" + props.data.cod_pedido_compra} type="button" className="btn btn-secondary btn-xs ms-2" title="Alterar Pedido Compra" data-bs-toggle="modal" data-bs-target="#modalPedido" onClick={props.onAlterarClick}>
                        <svg id={"alterar" + props.data.cod_pedido_compra} onClick={props.onAlterarClick} className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path id={"alterar" + props.data.cod_pedido_compra} onClick={props.onAlterarClick} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                }
            </td>
        </tr>
    )
}

export default PedidoCompraRow;