/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getParamger } from "../../services/ParamgerService";

function Paramger() {
  const inputTokenDropBox = useRef("");
  const inputUsaLog = useRef("");

  const history = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getParamger(token)
      .then((paramger) => {
        inputTokenDropBox.current.value = paramger.tokendropbox;
        inputUsaLog.current.value = paramger.usalog;
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          return history("/");
        }

        if (err.response) {
          setError(err.response.data);
        } else {
          setError(err.message);
        }
      });
  }, []);

  function onFormSubmit(event) { }

  return (
    <React.Fragment>
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h1 className="h4">Parâmetros</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card card-body border-0 shadow mb-4">
              <h2 className="h5 my-4">Geral</h2>
              <form onSubmit={onFormSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label htmlFor="tokendropbox">Token DropBox</label>
                      <input
                        className="form-control"
                        id="tokendropbox"
                        type="text"
                        ref={inputTokenDropBox}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label htmlFor="usalog">Usa Log</label>
                      <input
                        className="form-control"
                        id="usalog"
                        type="text"
                        ref={inputUsaLog}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                    <div className="col-sm-3">
                      <button
                        className="btn btn-gray-800 mt-2 animate-up-2"
                        type="button"
                        onClick={onFormSubmit}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                  {error ? (
                    <div className="alert alert-danger mt-2 col-9 py-2">
                      {error}
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {success ? (
                    <div className="alert alert-success mt-2 col-9 py-2">
                      {success}
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Paramger;
