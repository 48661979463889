// components/CadImpressora.js
import React, { useState, useEffect } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { getImpressoras, excluirImpressora } from "../../services/ImpressoraService";
import CadImpressoraRow from "./CadImpressoraRow";
import CadImpressoraModal from "./CadImpressoraModal/CadImpressoraModal";
import PesquisaImpressora from "./PesquisaImpressora";  // Atualize a importação aqui
import { Load } from "../../components/widgets/spinner/Spinner";

function CadImpressora() {
    const [pesquisaImpressora, setPesquisaImpressora] = useState("");
    const [impressoras, setImpressoras] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const DEFAULT_IMPRESSORA = { tipo: "", descricao: "", tipoConexao: "", caminho: "", enderecoBluetooth: "", guid: "", cod_org: localStorage.getItem("cod_org") };
    const token = localStorage.getItem("token");
    const [editImpressora, setEditImpressora] = useState(DEFAULT_IMPRESSORA);
    const cod_org = localStorage.getItem("cod_org")


    function onNovaImpressoraClick(event) {
        setEditImpressora(DEFAULT_IMPRESSORA);
    }

    function getImpressorasCall(token) {
        getImpressoras(cod_org, token)
            .then((result) => {
                setImpressoras(result);
            })
            .catch((err) => console.error(err.response ? err.response.data : err.message))
            .finally(() => setLoading(false));
    };



    //const [page, setPage] = useState(getPage());

    useEffect(() => {
        getImpressorasCall(token);
    }, [pesquisaImpressora]);

    function onAlterarClick(event) {
        const cod_impressora = event.target.id.replace("alterar", "");
        const impressora = impressoras.find((e) => e.cod_impressora == cod_impressora);
        setEditImpressora({ ...impressora });
    }

    function onExcluirClick(event) {
        const cod_impressora = event.target.id.replace("excluir", "");
        excluirImpressora(cod_impressora, token)
            .then(() => getImpressorasCall(token))
            .catch((err) => console.error(err.response ? err.response.data : err.message));
    }

    function onModalSubmit(event) {
        getImpressorasCall(token);
    }

    function onPesquisaImpressoraChange(event) {
        setPesquisaImpressora(event.target.value);
    }

    return (
        <React.Fragment>
            <main className="">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Cadastro Impressoras</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <button id="btnNovaImpressora" className="btn btn-primary animate-up-2" data-bs-toggle="modal" data-bs-target="#modalImpressora" onClick={onNovaImpressoraClick}>
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
                                </svg>
                                Nova Impressora
                            </button>
                        </div>
                        <div className="btn-group ms-2 ms-lg-3">
                            <PesquisaImpressora placeholder="Pesquisa impressora" onChange={onPesquisaImpressoraChange} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    {loading ? (
                        <Load />
                    ) : (
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="border-gray-200">Código</th>
                                    <th className="border-gray-200">Tipo</th>
                                    <th className="border-gray-200">Descrição</th>
                                    <th className="border-gray-200">Tipo de Conexão</th>
                                    <th className="border-gray-200">Caminho</th>
                                    <th className="border-gray-200">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {impressoras.map((impressora) => (
                                    <CadImpressoraRow key={impressora.cod_impressora} data={impressora} onAlterarClick={onAlterarClick} onExcluirClick={onExcluirClick} />
                                ))}
                            </tbody>
                        </table>
                    )}
                    <Pagination count={count} />
                </div>
            </main>
            <CadImpressoraModal data={editImpressora} onSubmit={onModalSubmit} />
        </React.Fragment>
    );
}

export default CadImpressora;
