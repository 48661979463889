import React, { useRef, useState, useEffect } from "react";
import { salvarEmpresa } from "../../../services/EmpresaService";
import { toast } from "react-toastify";
import Toast from "../../../components/Toast/Toast";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadEmpresaModal(props) {
  const [notification, setNotification] = useState({ type: "", text: "" });
  const [erro, setErro] = useState("");
  const [empresa, setEmpresa] = useState({});
  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setEmpresa(props.data);
  }, [props.data]);

  function onSubmit(event) {
    
    empresa.cod_org = localStorage.getItem("cod_org");

    if ((empresa.razao_social === undefined) || (empresa.razao_social.length < 3)) {
      return toast.warning("Insira a razão social para a sua empresa !!");
    }   
    
    if ((empresa.nome_fantasia === undefined) || (empresa.nome_fantasia.length < 3)) {
      return toast.warning("Insira o nome fantasia para a sua empresa !!");
    }   

    if ((empresa.logradouro === undefined) || (empresa.logradouro.length < 3)) {
      return toast.warning("Insira o logradouro para a sua empresa !!");
    }   

    if ((empresa.bairro === undefined) || (empresa.bairro.length < 3)) {
      return toast.warning("Insira o bairro para a sua empresa !!");
    }       

    if ((empresa.uf === undefined) || (empresa.uf.length < 1)) {
      return toast.warning("Insira a UF para a sua empresa !!");
    }  

    if ((empresa.cod_municipio === undefined) || (empresa.cod_municipio.length < 1)) {
      return toast.warning("Insira o código município para a sua empresa !!");
    }         

    if ((empresa.cidade === undefined) || (empresa.cidade.length < 3)) {
      return toast.warning("Insira a cidade para a sua empresa !!");
    }         

    if ((empresa.celular === undefined) || (empresa.celular.length < 3)) {
      return toast.warning("Insira o celular para a sua empresa !!");
    }          
    
    salvarEmpresa(empresa.cod_empresa, empresa, token)
      .then((result) => {
        setNotification({
          type: "success",
          text: "Registro gravado com sucesso!",
        });

        btnClose.current.click();
        if (props.onSubmit) props.onSubmit(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setErro(err.response ? err.response.data : err.message);
      });

    props.onSubmit(event);
  }

  function onInputChange(event) {
    setEmpresa((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="modalEmpresa"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTitleNotify"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="modalTitleNotify">
                {props.data.cod_empresa ? "Alterar " : "Nova "}Empresa
              </p>
              <button
                ref={btnClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="razao_social">Razão Social</label>
                    <input
                      className="form-control"
                      id="razao_social"
                      type="text"
                      placeholder="Informe a razão social"
                      defaultValue={empresa.razao_social}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="nome_fantasia">Nome Fantasia</label>
                    <input
                      className="form-control"
                      id="nome_fantasia"
                      type="text"
                      placeholder="Informe o nome fantasia"
                      defaultValue={empresa.nome_fantasia}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="logradouro">Logradouro</label>
                    <input
                      className="form-control"
                      id="logradouro"
                      type="text"
                      placeholder="Informe o logradouro"
                      defaultValue={empresa.logradouro}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="numero">Número</label>
                    <input
                      className="form-control"
                      id="numero"
                      type="text"
                      placeholder="Informe o número"
                      defaultValue={empresa.numero}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-md-9 mb-3">
                    <label htmlFor="bairro">Bairro</label>
                    <input
                      className="form-control"
                      id="bairro"
                      type="text"
                      placeholder="Informe o bairro"
                      defaultValue={empresa.bairro}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="uf">UF</label>
                    <input
                      className="form-control"
                      id="uf"
                      type="text"
                      placeholder="Informe a UF"
                      defaultValue={empresa.uf}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-md-9 mb-3">
                    <label htmlFor="cidade">Cidade</label>
                    <input
                      className="form-control"
                      id="cidade"
                      type="text"
                      placeholder="Informe a cidade"
                      defaultValue={empresa.cidade}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-06 mb-3">
                    <label htmlFor="cod_municipio">Cód Municipio</label>
                    <input
                      className="form-control"
                      id="cod_municipio"
                      type="text"
                      placeholder="Informe o Código Cidade"
                      defaultValue={empresa.cod_municipio}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-06 mb-3">
                    <label htmlFor="celular">Celular</label>
                    <input
                      className="form-control"
                      id="celular"
                      type="text"
                      placeholder="Informe o celular"
                      defaultValue={empresa.celular}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {erro ? (
                <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <button
                ref={btnSalvar}
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onSubmit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default CadEmpresaModal;
