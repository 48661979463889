/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { Load } from "../../../../components/widgets/spinner/Spinner";
import { geOrderByIdMenu } from "../../../../components/helpers/Api";
import "./OrderDetail.scss";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const OrderDetail = ({ ...props }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id, empresa } = useParams();
  const [additionalValue, setAdditionalValue] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("")

  const history = useNavigate();
  console.log(formaPagamento)
  useEffect(() => {
    socket.on(
      `update_status${JSON.parse(localStorage.getItem("consumerUser")).telefone
      }${localStorage.getItem("cod_org")}`,
      (res) => {
        if (res.cod_pedidomesa !== id) {
          handleGetOrderById();
        }
      }
    );
  }, [socket]);
  function handleGetOrderById() {
    geOrderByIdMenu(id)
      .then((res) => {
        res.data.data.tb_ipedidomesas.forEach((item) => {
          item.additional_value = 0;
          item.tb_ipedidomesaacoms.forEach((subItem) => {
            item.additional_value += parseFloat(subItem.valor);
          });
        });
        setData(res.data.data);
        setFormaPagamento(res.data.formaPagamento.descricao);
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    handleGetOrderById();
  }, [id]);
  return (
    <main className="menu-order-detail">
      <div className="orders-items">
        <div className="section-one">
          <p className="title">Detalhes do Pedido</p>
          <button
            onClick={() => history(`/menu/${empresa}/pedidos`)}
            className="back"
          >
            Voltar
          </button>
        </div>
        {loading ? (
          <Load />
        ) : (
          <>
            <div className="order-detail">
              <p className="text-comum">Pedido #{data.numero_pedidomesa}</p>
              <p className="text-comum">
                Status:{" "}
                <span
                  className={`${data.situacao === "analisando"
                    ? "analyzing"
                    : data.situacao === "produzindo"
                      ? "producing"
                      : data.situacao === "pronto"
                        ? "ready"
                        : ""
                    }`}
                >
                  {data.situacao}
                </span>
              </p>
            </div>
            <p className="text-comum">Itens do pedido</p>
            <ul className="order-detail-itens">
              {data?.tb_ipedidomesas?.map((item) => (
                <li key={item.cod_ipedidomesa}>
                  <span>
                    {item.quantidade}x {item.descricao}
                  </span>
                  <strong>
                    R${" "}
                    {(item.valor_unitario - item.additional_value).toFixed(2)}
                  </strong>
                  <br />
                  <ul className="order-detail-item">
                    {item.tb_ipedidomesaacoms.map((item, index) => (
                      <>
                        <li key={index + 1}>
                          <span>
                            {item.quantidade}x {item.web_descricao}
                          </span>
                          <strong>R$ {item.valor.toFixed(2)}</strong>
                        </li>
                      </>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
            <div className="valores">
              <p className="text-comum">
                Produtos: R$ {data?.valor_produtos?.toFixed(2)}
              </p>
              <p className="text-comum">
                Delivery: R$ {data?.valor_servico?.toFixed(2)}
              </p>
              <p className="text-comum">
                Total: R$ {data?.valor_total?.toFixed(2)}
              </p>
            </div>
            <div className="anothers-details">
              <label htmlFor="">Forma de Pagamento: {formaPagamento}</label>

            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default OrderDetail;
