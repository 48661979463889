import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const TAMANHO_URL = `${API_URL}/tamanhos/`;

export async function getTamanhos(cod_org, cod_secao) {
    const tagettamanhosUrl = `${TAMANHO_URL}${cod_org}?cod_secao=${cod_secao}`;
    const headers = {
        authorization: localStorage.getItem("token"),
    };

    const response = await axios.get(tagettamanhosUrl, { headers });
    console.log(response)
    return response;
}

export async function handleCreateTamanho(novoTamanho, token) {
    const headers = {
        authorization: localStorage.getItem("token"),
    };
    console.log(novoTamanho)
    const response = await axios.post(TAMANHO_URL, novoTamanho, { headers });
    return response.data;
}

export async function updateTamanho(id, tamanhoAtualizado, token) {
    const headers = { authorization: token };
    const response = await axios.put(`${TAMANHO_URL}${id}`, tamanhoAtualizado, { headers });
    return response.data;
}

export async function deleteTamanho(id, token) {
    const headers = { authorization: token };
    const response = await axios.delete(`${TAMANHO_URL}${id}`, { headers });
    return response.data;
}

export async function getTamanhoByCod(id) {
    const headers = {
        authorization: localStorage.getItem("token"),
    };
    const response = await axios.get(`${TAMANHO_URL}?produtoId=${id}`, { headers });
    return response.data;
}


