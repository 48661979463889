import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const PREVENDA_URL = `${API_URL}/prevenda/`;

export async function getPreVendas(nome_cliente, page, token) {

    const prevendaUrl = `${PREVENDA_URL}${nome_cliente}?page=${page}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(prevendaUrl, { headers });
    return response.data;
}

export async function salvarPreVenda(cod_pre_venda, novaPreVenda, token) {

    const headers = { 'authorization': token }

    let response;

    if (cod_pre_venda) {
        response = await axios.patch(`${PREVENDA_URL}${cod_pre_venda}`, novaPreVenda, { headers });
    } else {
        let venda = { ...novaPreVenda, cod_org: localStorage.getItem("cod_org") }
        response = await axios.post(`${PREVENDA_URL}`, venda, { headers });
    }

    return response.data;
}

export async function excluirPreVenda(cod_pre_venda, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${PREVENDA_URL}${cod_pre_venda}`, { headers });
    return response.data;
}