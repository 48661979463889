import React from "react";
import ReactDOM from "react-dom/client";  // Alterado para 'react-dom/client'
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./components/authentication/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./index.scss";

// Crie a raiz usando `createRoot` ao invés de `ReactDOM.render`
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter >
      <AuthContext>
        <Routes />
      </AuthContext>
      <ToastContainer autoClose={2500} />
    </BrowserRouter>
  </React.StrictMode>
);

// Medição de performance (opcional)
reportWebVitals();
