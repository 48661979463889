import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const MESA_URL = `${API_URL}/mesa/`;

export async function getMesas(descricao, page, per_page) {

  const codOrg = localStorage.getItem("cod_org");
  const mesasUrl = `${MESA_URL}${descricao}?page=${page}&cod_org=${codOrg}&per_page=${per_page}`;
  const headers = {
    authorization: localStorage.getItem("token"),
  };

  const response = await axios.get(mesasUrl, { headers });
  return response.data;
}

export async function salvarMesa(cod_mesa, novaMesa, token) {
  const headers = { authorization: token };

  let response;

  if (cod_mesa) {
    response = await axios.patch(`${MESA_URL}${cod_mesa}`, novaMesa, {
      headers,
    });
  } else {
    response = await axios.post(`${MESA_URL}`, novaMesa, { headers });
  }

  return response.data;
}

export async function excluirMesa(cod_mesa, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${MESA_URL}${cod_mesa}`, { headers });
  return response.data;
}

export async function getTipoMesa(tipo) {
  const codOrg = localStorage.getItem("cod_org");
  const mesasUrl = `${MESA_URL}tipo-mesas?tipo=${tipo}&cod_org=${codOrg}`;
  const headers = {
    authorization: localStorage.getItem("token"),
  };

  const response = await axios.get(mesasUrl, { headers });
  return response.data;
}

