/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useRef, useState, useEffect } from "react";
import Toast from "../../components/Toast/Toast";
import { getParceiroPorEmail } from "../../services/ParceiroService";
import { informaPreco } from "../../services/PrecoIpedidoCompraService";
import { formatarMoeda, formataValorSemSimbolo } from "../../utils/utils";

/**
 * props:
 * - data
 * - onSubmit
 */

function InformaPrecoModal(props) {
  const [notification, setNotification] = useState({ type: "", text: "" });
  const [itensPedidoCompra, setItensPedidoCompra] = useState({
    cod_itens_pedido_compra: "",
  });
  const [precoIpedidoCompra, setPrecoIpedidoCompra] = useState({});
  const [codParceiro, setCodParceiro] = useState("");

  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const email = localStorage.getItem("sistemacemail");

    getParceiroPorEmail(email, token)
      .then((response) => {
        if (response) {
          setCodParceiro(response.cod_parceiro);
        } else {
          setCodParceiro(0);
        }
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  useEffect(() => {
    setItensPedidoCompra(props.data);

    if (props.data.tb_preco_ipedido_compras) {
      return props.data.tb_preco_ipedido_compras.map((preco) => {
        if (preco.cod_pk_fornecedor === codParceiro) {
          precoIpedidoCompra.preco_unitario = formataValorSemSimbolo(
            preco.preco_unitario
          );
        }
      });
    }
  }, [props.data]);

  function onSubmit(event) {
    setError("");

    event.preventDefault();

    const email = localStorage.getItem("sistemacemail");

    if (precoIpedidoCompra.preco_unitario > 0) {
      getParceiroPorEmail(email, token)
        .then((response) => {
          if (response) {
            precoIpedidoCompra.cod_itens_pedido_compra =
              itensPedidoCompra.cod_itens_pedido_compra;
            precoIpedidoCompra.cod_pk_fornecedor = response.cod_parceiro;

            informaPreco(precoIpedidoCompra, token)
              .then((result) => {
                setNotification({
                  type: "success",
                  text: "Preço gravado com sucesso!",
                });

                btnClose.current.click();

                if (props.onSubmit) props.onSubmit(result);
              })
              .catch((err) => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
              });
          } else {
            setNotification({
              type: "error",
              text: "Fornecedor não encontrado",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setError(`Fornecedor não encontrado`);
        });
    } else {
      setError(`Informe um valor`);
    }
  }

  function onInputChange(event) {
    setError("");

    let valor = formatarMoeda(event);

    valor = valor.replace(".", "");
    valor = valor.replace(",", ".");

    if (valor > 0) {
      setPrecoIpedidoCompra((prevState) => ({
        ...prevState,
        ["preco_unitario"]: valor,
      }));
    }
  }

  return (
    <React.Fragment>
      <div
        className="modal fade bd-example-modal-lg"
        id="modalPreco"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTitleNotify"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="modalTitleNotify">
                {props.data.cod_itens_pedido_compra ? "Informa Preço " : ""}
                {props.data.descricao}{" "}
              </p>
              <button
                ref={btnClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="Preço" align="Left">
                    Preço
                  </label>
                  <input
                    className="form-control"
                    id="preco_unitario"
                    type="text"
                    placeholder="Informe um valor"
                    defaultValue={precoIpedidoCompra.preco_unitario}
                    onChange={onInputChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                {error ? (
                  <div className="alert alert-danger mt-1 col-9 py-1">
                    {error}
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <button
                  ref={btnSalvar}
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={onSubmit}
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default InformaPrecoModal;
