import React from 'react';

const Receipt = React.forwardRef(({ pedido }, ref) => (
    <div ref={ref} style={{ width: '80mm', fontFamily: 'monospace', padding: '10px' }}>
        {pedido ? (
            <>
                <div style={{ textAlign: 'center' }}>
                    <p>Ped. No.: {pedido.numero_pedidomesa}</p>
                    <p>Data: {new Date(pedido.createdAt).toLocaleDateString()}</p>
                </div>
                <hr />
                <div>
                    <p>Mesa: {pedido.cod_mesa}</p>
                    <p>Atendente: {pedido.cod_funcionario || 'BALCAO'}</p>
                </div>
                <hr />
                <div>
                    {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.length > 0 ? (
                        pedido.tb_ipedidomesas.map((item, index) => (
                            <div key={index}>
                                <p>Qtde  Produto</p>
                                <p>{item.quantidade}  {item.descricao}</p>
                                {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.length > 0 && (
                                    <div>
                                        <p>Sabores:</p>
                                        {item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                            <p key={acompIndex}>- {acomp.descricao}</p>
                                        ))}
                                    </div>
                                )}
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p>Nenhum item encontrado</p>
                    )}
                </div>
            </>
        ) : (
            <p>Carregando...</p>
        )}
    </div>
));

export default Receipt;