import React, { useEffect, useState } from "react";
import "./ConsumerModal.scss";
import { Box, Modal } from "@mui/material";
import { getConsumers } from "../../../helpers/Api";
import NewConsumerModal from "./AddCliente";

const ConsumerModal = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [searchConsumer, setSearchConsumer] = useState("");
  const [data, setData] = useState([]);
  const [selectedConsumer, setSelectedConsumer] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [openNewConsumerModal, setOpenNewConsumerModal] = useState(false); // Estado para controlar o novo modal

  useEffect(() => {
    handleGetConsumers();
  }, []);

  function handleGetConsumers() {
    setLoading(true);
    getConsumers(searchConsumer, 1)
      .then((res) => {
        setData(res.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleGetConsumers();
  }, [searchConsumer]);

  return (
    <main>
      <Modal open={props.open} onClose={() => []}>
        <Box className="consumer-modal">
          <section className="first-section">
            <div className="title-content">
              <p className="title-modal">
                {selectedConsumer ? "Endereços do" : ""} Clientes
              </p>
              <i
                className="fa-solid fa-xmark"
                role="button"
                onClick={() => props.setFalse()}
              ></i>{" "}
            </div>
            { }
            {selectedConsumer ? (
              <>
                <button
                  className="back w-100"
                  style={{ height: "35px" }}
                  onClick={() => setSelectedConsumer("")}
                >
                  Voltar
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquise pelo nome do Cliente"
                  value={searchConsumer}
                  onChange={(e) => [
                    setSearchConsumer(e.target.value),
                    // handleGetConsumers(e.target.value),
                  ]}
                />
                <button
                  className="save w-100 mt-2"
                  onClick={() => setOpenNewConsumerModal(true)} // Abrindo o novo modal
                >
                  Adicionar Novo Cliente
                </button>
              </>
            )}
          </section>
          <section className="second-section">
            {selectedConsumer ? (
              <>
                {selectedConsumer?.tb_endereco_usuario_consumidors?.length ===
                  0 ? (
                  <div>
                    <p className="fw-bold fs-5 mt-3">
                      O cliente não tem endereço Cadastrado
                    </p>
                  </div>
                ) : (
                  <>
                    {selectedConsumer?.tb_endereco_usuario_consumidors?.map(
                      (item) => (
                        <div
                          className="consumer-addres"
                          key={item.cod_endereco}
                          onClick={() => [
                            setSelectedConsumer(""),
                            props.setFalse(),
                            props.setAddress(
                              `${item.rua} ${item.numero ? `,${item.numero} ` : ""
                              }${item.complemento ? `,${item.complemento} ` : ""
                              }- ${item.bairro} - ${item.cidade} ${item.referencia ? `${item.referencia}` : ""
                              }`
                            ),
                          ]}
                        >
                          <span> {item.rua}</span>, <span> {item.numero}</span>,
                          <span> {item.complemento}</span> -
                          <span> {item.bairro}</span> -
                          <span> {item.cidade}</span>
                        </div>
                      )
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {data?.map((item) => (
                  <div
                    className="consumer-item"
                    key={item.cod_usuario_consumidor}
                    onClick={() => [
                      setSelectedConsumer(item),
                      props.setDataConsumer(item),
                    ]}
                  >
                    {item.nome}
                  </div>
                ))}
              </>
            )}
          </section>

          {/* Consumer Modal */}
        </Box>
      </Modal>

      <NewConsumerModal
        open={openNewConsumerModal}
        onClose={() => setOpenNewConsumerModal(false)}
        onSave={(newConsumer) => {
          setSelectedConsumer(newConsumer);
          setOpenNewConsumerModal(false);
        }}
      />
    </main>
  );
};

export default ConsumerModal;
