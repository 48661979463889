import React, { useRef, useState, useEffect } from "react";
import { salvarFuncionario } from "../../../services/FuncionarioService";
import SwitchInput from "./SwitchInput";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadFuncionarioModal(props) {
  const [erro, setErro] = useState("");
  const [funcionario, setFuncionario] = useState({});
  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");
  const cod_org = localStorage.getItem("cod_org");

  useEffect(() => {
    setFuncionario(props.data);
  }, [props.data]);

  function onSubmit(event) {
    salvarFuncionario(funcionario.cod_funcionario, funcionario, cod_org, token)
      .then((result) => {
        btnClose.current.click();
        if (props.onSubmit) props.onSubmit(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setErro(err.response ? err.response.data : err.message);
      });

    props.onSubmit(event);
  }

  function onInputChange(event) {
    setFuncionario((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  return (
    <div
      className="modal fade"
      id="modalFuncionario"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {props.data.cod_funcionario ? "Alterar " : "Nova "}Funcionario
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="nome_funcionario">Nome</label>
                  <input
                    className="form-control"
                    id="nome_funcionario"
                    type="text"
                    placeholder="Informe a nome do Funcionário"
                    defaultValue={funcionario.nome_funcionario}
                    onChange={onInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-9 mb-3">
                  <label htmlFor="tipo" align="Left">
                    Função
                  </label>
                  <select
                    className="form-control"
                    value={funcionario.funcao}
                    name="funcaoFuncionario"
                    id="funcao"
                    onChange={onInputChange}
                  >
                    <option value="Gerente">Gerente</option>
                    <option value="Vendedor">Vendedor</option>
                    <option value="Caixa">Caixa</option>
                    <option value="Mecanico">Mecanico</option>
                    <option value="Tecnico">Tecnico</option>
                    <option value="Entregador">Entregador</option>
                    <option value="Administrador">Administrador</option>
                  </select>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <label htmlFor="tipo" align="Left">
                      Tipo
                    </label>
                    <SwitchInput
                      id="listar_secao"
                      text={funcionario.situacao}
                      onChange={onInputChange}
                      isChecked={funcionario.situacao}
                    />
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </div>
          <div className="modal-footer">
            {erro ? (
              <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <button
              ref={btnSalvar}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onSubmit}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadFuncionarioModal;
