import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const IMPRESSORA_URL = `${API_URL}/impressoras/`;

export async function salvarImpressora(cod_org, novaImpressora, token) {
    let response;
    if (!novaImpressora.cod_impressora) {
        const headers = { 'authorization': token }
        response = await axios.post(`${IMPRESSORA_URL}/`, novaImpressora, { headers });
    } else {
        response = axios.put(`${API_URL}/${IMPRESSORA_URL}/${novaImpressora.cod_impressora}/`)
    }

    return response.data;
}

export async function excluirImpressora(cod_impressora, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${IMPRESSORA_URL}${cod_impressora}`, { headers });
    return response.data;
}

export async function getImpressoraByCod(cod_impressora, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${IMPRESSORA_URL}${cod_impressora}`, { headers });
    return response.data;
}

export async function getImpressoras(cod_org, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${IMPRESSORA_URL}${cod_org}`, { headers });
    return response.data;
}

export async function imprimiPedido(pedido, cod_impressora, token) {
    const headers = { 'authorization': token };
    const body = { pedido, cod_impressora }
    const response = await axios.post(`${IMPRESSORA_URL}pedido`, body, { headers });
    return response.data;
}
