import React, { useState, useEffect } from "react";
import { getProdutosAdicionais, getProdutosAcompbyId } from "../../../services/ProdutoService";

export function CadAddProdutos({ setSelectedsAdd, isAdditional, id }) {
    const [adicionais, setAdicionais] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredAdicionais, setFilteredAdicionais] = useState([]);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            getProdutosAdicionais(token).then((res) => {
                const adicionaisData = res.data.map((adicional) => ({
                    ...adicional,
                    selected: false,
                }));
                setAdicionais(adicionaisData);
                setFilteredAdicionais(adicionaisData);


                if (id) {
                    getProdutosAcompbyId(token, id).then((res) => {
                        const associados = res.data.map((item) => item.cod_acomp);
                        console.log(associados) // Array apenas com os IDs cod_acomp
                        const updatedAdicionais = adicionaisData.map((adicional) => ({
                            ...adicional,
                            selected: associados.includes(adicional.cod_produto), // Verifica se cod_acomp está nos associados
                        }));

                        setAdicionais(updatedAdicionais);
                        setFilteredAdicionais(updatedAdicionais);

                        const selectedItems = updatedAdicionais.filter((item) => item.selected);
                        setSelectedsAdd(selectedItems);
                    });
                }
            });
        }
    }, [token, id, setSelectedsAdd]);

    useEffect(() => {
        const filtered = adicionais.filter((adicional) =>
            adicional.descricao.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredAdicionais(filtered);
    }, [search, adicionais]);

    const handleSelect = (selectedAdicional) => {
        const updatedAdicionais = adicionais.map((item) => {
            if (item.cod_produto === selectedAdicional.cod_produto) {
                return { ...item, selected: !item.selected };
            }
            return item;
        });

        setAdicionais(updatedAdicionais);

        const selectedItems = updatedAdicionais.filter((item) => item.selected);
        setSelectedsAdd(selectedItems);
    };
    console.log(id)
    return (
        <div>
            <div className="search-bar mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar adicionais..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Selecionar</th>
                            <th>Descrição</th>
                            <th>Preço</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAdicionais.length > 0 ? (
                            filteredAdicionais.map((adicional) => (
                                <tr key={adicional.cod_produto}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={adicional.selected || false}
                                            onChange={() => handleSelect(adicional)}
                                        />
                                    </td>
                                    <td>{adicional.descricao}</td>
                                    <td>{adicional.preco}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">
                                    Nenhum adicional encontrado
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
