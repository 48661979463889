import React, { useEffect, useState } from "react";
import "./SectionAdditionalModal.scss";
import { getSecoes } from "../../../../services/SecaoService";
import { CadAddProdutos } from "../../../../private/CadProduto/CadProdutoModal/CadAddAdicionais";

const SectionAdditionalModal = ({ ...props }) => {
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [deletedIndexies, setDeletedIndexies] = useState([]);
  const [showCadAdd, setShowCadAdd] = useState(false); // Estado para controlar a exibição do CadAddProdutos

  useEffect(() => {
    handleGetSections();
  }, []);
  useEffect(() => {
    setHasIndexies(props.ids);
  }, [props.ids]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function handleGetSections() {
    getSecoes("", 1, null, "all", "S")
      .then((res) => {
        let cod = localStorage.getItem("cod_org")
        let filter = res.rows.filter(i => i.cod_org.toString() === cod.toString());
        setSections(filter);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }

  function handleDeleteById(category) {
    const newArray = [];
    hasIndexies.forEach((item) => {
      return item !== category
        ? newArray.push(item)
        : props.ids.includes(item)
          ? [
            setDeletedIndexies([...deletedIndexies, item]),
            props.deletedIds([...deletedIndexies, item]),
          ]
          : "";
    });
    setHasIndexies(newArray);
    props.setIds(newArray);

    const newData = [];
    data.forEach((item) => {
      return item.cod_secao !== category ? newData.push(item) : "";
    });
    setData(newData);
  }


  function handleRestauredIDs(category) {
    if (deletedIndexies.includes(category)) {
      const filtered = deletedIndexies.filter((item) => item !== category);
      setDeletedIndexies(filtered);
      props.deletedIds(filtered);
    }
  }

  // Função para alternar a visibilidade do componente CadAddProdutos
  function toggleCadAdd() {
    setShowCadAdd((prevShowCadAdd) => !prevShowCadAdd);
  }

  return (
    <main>
      <div id="additional-modal">
        <div className="title-content">
          <p className="title-modal">Selecione os adicionais</p>
        </div>
        <section>
          <div className="additional-modal-items">
            {sections?.map((item, index) =>
            (
              <label className=" mt-2 additional-modal-item" key={index + 1}>
                <span className="category-title">
                  <img
                    width={50}
                    height={50}
                    alt=""
                    crossOrigin="anonymous"
                    src={process.env.REACT_APP_API_URL + `/${item.imagem}`}
                  />{" "}
                  <span>{item.descricao}</span>
                </span>

                <input
                  checked={hasIndexies?.includes(item.cod_secao)}
                  type="checkbox"
                  style={{ width: 20, height: 20, marginLeft: 40 }}
                  onChange={() => []}
                  onClick={() => [
                    props.setIds([...hasIndexies, item.cod_secao]),
                    setData([...data, item]),
                    hasIndexies?.includes(item.cod_secao)
                      ? handleDeleteById(item.cod_secao)
                      : setHasIndexies([...hasIndexies, item.cod_secao]),
                    handleRestauredIDs(item.cod_secao),
                  ]}
                />
              </label>
            ))}
          </div>
        </section>

        {hasIndexies.length > 0 ? (
          <CadAddProdutos
            setSelectedsAdd={props.setSelectedsAdd}
            isAdditional={props.isAdditional}
            id={props?.data[0]?.tb_secao_produto?.cod_produto}
          />
        ) : ""}
      </div>
    </main>
  );
};

export default SectionAdditionalModal;
