import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import PrivateRoutes from "./PrivateRoutes";
import MasterLayout from "../components/layout/Menu/MasterLayout.js";
import { Context } from "../components/authentication/AuthContext";
import Empresa from "../public/establishment/Empresa";
import SearchUrl from "../private/Search-Url/SearchUrl";
import OrdersList from "../public/establishment/orders/orders-list/OrdersList";
import OrderDetail from "../public/establishment/orders/order-detail/OrderDetail";
import Cart from "../components/partials/modals/Cart";
import CloserOrder from "../public/establishment/orders/order-close/CloseOrder";
import Login from "../public/Login/Login";

const Rout = () => {
  const { isAuthenticated, searchingUrl } = useContext(Context);

  return (
    <Routes>
      {/* <Route path="/:cliente" element={<Navigate to="/:cliente/auth/login" />} /> */}
      <Route path="/:cliente/auth/login" element={<Login />} />
      <Route path="/menu/:empresa" element={<Empresa />} />
      <Route path="/menu/:empresa/pedidos" element={<OrdersList />} />
      <Route path="/menu/:empresa/pedido/:id" element={<OrderDetail />} />
      <Route path="/menu/:empresa/carrinho" element={<Cart />} />
      <Route path="/menu/:empresa/fechar-pedido" element={<CloserOrder />} />

      {searchingUrl && !isAuthenticated && !localStorage.getItem("cod_org") ? (
        <Route path="/:url?" element={<SearchUrl />} />
      ) : (
        <>
          {!isAuthenticated ? (
            <Route path="/:cliente/auth/login" element={<Login />} />
          ) : (
            <Route path="*" element={<MasterLayout><PrivateRoutes /></MasterLayout>} />
          )}
        </>
      )}
    </Routes>
  );
};

export default Rout;
