/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useRef, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { Box, Modal } from "@mui/material";
import "./CadClienteModal.scss";
import { toast } from "react-toastify";
import {
  createConsumer,
  updateConsumer,
} from "../../../components/helpers/Api.jsx";
/**
 * props:
 * - data
 * - onSubmit
 */

function CadClienteModal(props) {
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(props.data.nome);
    setCellPhone(props.data.telefone);
  }, [props.data]);
  function handleCreateUser() {
    if (cellPhone.length < 14 || cellPhone.includes("_")) {
      return toast.error("Insira um número de telefone válido");
    }
    if (name.length < 5) {
      return toast.error("Nome deve ter no minímo 5 caracteres");
    }
    setLoading(true);
    let data = {
      telefone: cellPhone,
      nome: name,
      cod_org: localStorage.getItem("cod_org")
    };

    if (props.data.cod_usuario_consumidor) {
      updateConsumer(data, props.data.cod_usuario_consumidor)
        .then((res) => {
          if (res.status === 201) {
            setCellPhone("");
            setName("");
            props.onSubmit();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createConsumer(data)
        .then((res) => {
          if (res.status === 201) {
            setCellPhone("");
            setName("");
            props.onSubmit();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box id="box-mui-consumer" className="">
          <main className="cliente-modal">
            <section className="first-section">
              <p className="title-modal">Novo Cliente</p>
              <i
                className="fa-solid fa-xmark"
                role="button"
                onClick={() => props.setFalse()}
              ></i>{" "}
            </section>
            <section className="second-section">
              <label className="form-control fw-bolder">
                Número de Telefone:
                <InputMask
                  style={{ color: "black" }}
                  className="form-control "
                  mask="(99)99999-9999"
                  placeholder="Ex: (11)99342-3247"
                  onChange={(e) => setCellPhone(e.target.value)}
                  autoComplete="off"
                  value={cellPhone}
                />
              </label>
              <label className="form-control mt-4 fw-bolder">
                Nome e Sobrenome:
                <input
                  style={{ color: "black" }}
                  className="form-control"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Ex: João Souza"
                />
              </label>
            </section>
            <button className="save" onClick={() => handleCreateUser()}>
              {!loading && <span className="indicator-label">Salvar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </main>
        </Box>
      </Modal>
    </>
  );
}

export default CadClienteModal;
