import React from 'react';

const EntregaPrint = React.forwardRef(({ pedido }, ref) => (
    <div ref={ref} style={{ width: '80mm', fontFamily: 'monospace', padding: '10px' }}>
        {pedido ? (
            <>
                <div style={{ textAlign: 'center' }}>

                    <p>*** NÃO É DOCUMENTO FISCAL ***</p>
                    <hr />
                    <p>Pedido: {pedido.numero_pedidomesa}</p>
                    <p>Impresso: {new Date().toLocaleString()}</p>
                    <p>Endereço: {pedido.endereco}</p>
                    <hr />
                    <div>
                        {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.length > 0 ? (
                            pedido.tb_ipedidomesas.map((item, index) => (
                                <div key={index}>
                                    <p>{item.descricao}</p>
                                    <p>Qtde: {item.quantidade}  V. Unit.: {item.valor_unitario.toFixed(2)}  Total: {(item.valor_unitario * item.quantidade).toFixed(2)}</p>
                                    {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.length > 0 && (
                                        <div>
                                            <p>Sabores:</p>
                                            {item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                                <p key={acompIndex}>- {acomp.descricao}</p>
                                            ))}
                                        </div>
                                    )}
                                    <hr />
                                </div>
                            ))
                        ) : (
                            <p>Nenhum item encontrado</p>
                        )}
                    </div>
                    <p>Total Produtos: {pedido.valor_produtos.toFixed(2)}</p>
                    <p>Serviço: {pedido.valor_servico.toFixed(2)}</p>
                    <p>Valor Total: {pedido.valor_total.toFixed(2)}</p>
                    <p>Total Pago: {pedido.valor_pago ? pedido.valor_pago.toFixed(2) : '0.00'}</p>
                    <p>Restante: {(pedido.valor_total - (pedido.valor_pago || 0)).toFixed(2)}</p>
                </div>
            </>
        ) : (
            <p>Carregando...</p>
        )}
    </div>
));

export default EntregaPrint;
