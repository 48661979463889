import React, { useRef, useState, useEffect } from "react";
import { salvarTributacao } from "../../../services/TributacaoService";
import Toast from "../../../components/Toast/Toast";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadTributacaoModal(props) {
  const [notification, setNotification] = useState({ type: "", text: "" });
  const [erro, setErro] = useState("");
  const [tributacao, setTributacao] = useState({});
  const token = localStorage.getItem("token");

  const btnClose = useRef("");
  const btnSalvar = useRef("");

  useEffect(() => {
    setTributacao(props.data);
  }, [props.data]);

  function onSubmit(event) {
    
    tributacao.cod_org = localStorage.getItem("cod_org");
    
    salvarTributacao(tributacao.cod_tributacao, tributacao, token)
      .then((result) => {
        setNotification({
          type: "success",
          text: "Registro gravado com sucesso!",
        });

        btnClose.current.click();

        if (props.onSubmit) props.onSubmit(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setErro(err.response ? err.response.data : err.message);
      });

    props.onSubmit(event);
  }

  function onInputChange(event) {
    setTributacao((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="modalTributacao"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTitleNotify"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="modalTitleNotify">
                {props.data.cod_tributacao ? "Alterar " : "Nova "}Tributação
              </p>
              <button
                ref={btnClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="descricao" align="Left">
                      Descrição
                    </label>
                    <input
                      className="form-control"
                      id="descricao"
                      type="text"
                      placeholder="Informe a Descrição"
                      defaultValue={tributacao.descricao}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="sit_trib_entrada" align="Left">
                      Sit. Trib. Entrada
                    </label>
                    <input
                      className="form-control"
                      id="sit_trib_entrada"
                      type="text"
                      placeholder=""
                      defaultValue={tributacao.sit_trib_entrada}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="sit_trib_fora" align="Left">
                      Sit. Trib. Saida
                    </label>
                    <input
                      className="form-control"
                      id="sit_trib_saida"
                      type="text"
                      placeholder=""
                      defaultValue={tributacao.sit_trib_saida}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label htmlFor="Aliq Dentro" align="Left">
                      Aliquota Dentro
                    </label>
                    <input
                      className="form-control"
                      id="aliq_trib1_dentro"
                      type="text"
                      placeholder=""
                      defaultValue={tributacao.aliq_trib1_dentro}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="Aliq Fora" align="Left">
                      Aliquota Fora
                    </label>
                    <input
                      className="form-control"
                      id="aliq_trib1_fora"
                      type="text"
                      placeholder=""
                      defaultValue={tributacao.aliq_trib1_fora}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="cfop_saida" align="Left">
                      CFOP de Saida
                    </label>
                    <input
                      className="form-control"
                      id="cfop_saida"
                      type="text"
                      placeholder=""
                      defaultValue={tributacao.cfop_saida}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {erro ? (
                <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <button
                ref={btnSalvar}
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onSubmit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default CadTributacaoModal;
