import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const PEDIDOCOMPRA_URL = `${API_URL}/pedidocompra/`;

export async function getPedidoCompras(fornecedor, page, token) {

    const pedidoCompraUrl = `${PEDIDOCOMPRA_URL}${fornecedor}?page=${page}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(pedidoCompraUrl, { headers });
    return response.data;
}

export async function getPedidoCompra(cod_pedido_compra, token) {

    const headers = { 'authorization': token };
    const response = await axios.get(`${PEDIDOCOMPRA_URL}${cod_pedido_compra}`, { headers });
    return response.data;
}