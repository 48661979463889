/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import CadMeioPagamentoModal from "./CadMeioPagamentoModal/CadMeioPagamentoModal.jsx";
import SearchConsumer from "./PesquisaMeioPagamento";
import {
  deletePaymentMethod,
  getPaymentMethod,
} from "../../components/helpers/Api";
import { Load } from "../../components/widgets/spinner/Spinner";

function CadMeioPagamento({ ...props }) {
  const [searchPaymentMethod, setPaymentMehotd] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const defaultLocation = useLocation();
  const [loading, setLoading] = useState(false);

  const [editPayment, setEditPayment] = useState({});

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function handleGetPaymentMethod() {
    setLoading(true);
    getPaymentMethod(searchPaymentMethod, page || 1)
      .then((res) => {
        setData(res.data.rows);
        setCount(res.data.count);
        setOpenPaymentModal(false);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    setPage(getPage(history));
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    handleGetPaymentMethod();
  }, [page, searchPaymentMethod]);

  function handleDeletePaymentMethod(id) {
    deletePaymentMethod(id)
      .then((res) => { })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.msg);
      })
      .finally(() => {
        handleGetPaymentMethod();
      });
  }

  function onModalSubmit(event) {
    setTimeout(function () {
      history.go(0);
    }, 1000);
  }

  function handleSearchPaymentMethod(event) {
    setPaymentMehotd(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Formas de Pagamentos</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovoCliente"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalCliente"
                onClick={() => setOpenPaymentModal(true)}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Nova F. de Pagamento
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <SearchConsumer
                placeholder="Pesquisar Forma de Pagamento"
                onChange={handleSearchPaymentMethod}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          {loading ? (
            <Load />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200 text-center">Código</th>
                  <th className="border-gray-200 text-center">Descrição</th>
                  <th className="border-gray-200 text-center">Tipo</th>
                  <th className="border-gray-200 text-center">Status</th>
                  <th className="border-gray-200 text-center">Ações</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {data?.map((item) => (
                    <tr key={item.cod_forma_pgto}>
                      <td className="text-center">{item.cod_forma_pgto}</td>
                      <td className="text-center">{item.descricao}</td>
                      <td className="text-center">{item.tipo}</td>
                      <td className="text-center">
                        {item.status === "1" ? "Ativo" : "Inativo"}
                      </td>
                      <td className="text-center">
                        {
                          <button
                            id={"alterar" + item.cod_forma_pgto}
                            type="button"
                            className="btn btn-secondary btn-xs ms-2"
                            title="Alterar Parceiro"
                            data-bs-toggle="modal"
                            onClick={() => [
                              setOpenPaymentModal(true),
                              setEditPayment(item),
                            ]}
                          >
                            <svg
                              id={"alterar" + item.cod_forma_pgto}
                              className="icon icon-xs"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id={"alterar" + item.cod_forma_pgto}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                        }
                        {
                          <button
                            id={"excluir" + item.cod_forma_pgto}
                            type="button"
                            className="btn btn-danger btn-xs ms-2"
                            title="Delete this Template"
                            onClick={() =>
                              handleDeletePaymentMethod(item.cod_forma_pgto)
                            }
                          >
                            <svg
                              id={"excluir" + item.cod_forma_pgto}
                              className="icon icon-xs"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id={"excluir" + item.cod_forma_pgto}
                                fillRule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          )}

          <Pagination count={count} />
        </div>
      </main>
      <CadMeioPagamentoModal
        open={openPaymentModal}
        setFalse={() => setOpenPaymentModal(false)}
        data={editPayment}
        onSubmit={() => handleGetPaymentMethod()}
      />
    </React.Fragment>
  );
}

export default CadMeioPagamento;
