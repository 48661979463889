/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

function Pdv() {
  return (
    <React.Fragment>
      <script src="/vendor/@popperjs/core/dist/umd/popper.min.js"></script>
      <script src="/vendor/bootstrap/dist/js/bootstrap.min.js"></script>

      <div class="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="card card-body border-2 shadow table-wrapper table-responsive">
              <div className="row mb-1">
                <img src="img/logoexemplo.bmp" class="img-200-200" alt="..." />
              </div>
              <hr></hr>

              <nav>
                <div
                  class="nav nav-tabs form-control form-control row"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    class="nav-link active col-md-4 btn"
                    id="nav-produto-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-produto"
                    type="button"
                    role="tab"
                    aria-controls="nav-produto"
                    aria-selected="true"
                  >
                    Produto
                  </button>
                  <button
                    class="nav-link col-md-4 btn"
                    id="nav-parceiro-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-parceiro"
                    type="button"
                    role="tab"
                    aria-controls="nav-parceiro"
                    aria-selected="false"
                  >
                    Parceiro
                  </button>
                  <button
                    class="nav-link col-md-4 btn"
                    id="nav-vendedor-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-vendedor"
                    type="button"
                    role="tab"
                    aria-controls="nav-vendedor"
                    aria-selected="false"
                  >
                    Vendedor
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-produto"
                  role="tabpanel"
                  aria-labelledby="nav-produto-tab"
                >
                  <div ClassName="row">
                    <div className="col-md-12 mb-1">
                      <br />
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Buscar Produto"
                          aria-describedby="button-addon2"
                        />
                        <button
                          class="btn btn-outline-info"
                          type="button"
                          id="button-addon2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </button>
                      </div>
                      <hr></hr>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-sm-12">
                      <label ClassName="form-control" align="Left">
                        Descrição do Produto
                      </label>
                      <input
                        className="form-control form-control"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-4">
                      <label
                        ClassName="text-sm"
                        htmlFor="descricao"
                        align="Left"
                      >
                        Quantidade
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="0,00"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="descricao" align="Left">
                        Descontos %
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="0,00"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="descricao" align="Left">
                        Valor Unitario
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="0,00"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-parceiro"
                  role="tabpanel"
                  aria-labelledby="nav-parceiro-tab"
                >
                  <div ClassName="row">
                    <div className="col-md-12 mb-1">
                      <br />
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="Digite o codigo ou nome do Parceiro"
                      />
                      <hr></hr>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <label htmlFor="descricao" align="Left">
                        Razao Social
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-6">
                      <label htmlFor="descricao" align="Left">
                        Nome Fantasia
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-7">
                      <label htmlFor="descricao" align="Left">
                        Endereço
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-2">
                      <label htmlFor="descricao" align="Left">
                        Numero
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-3">
                      <label htmlFor="descricao" align="Left">
                        Bairro
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-2">
                      <label htmlFor="descricao" align="Left">
                        UF
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-4">
                      <label htmlFor="descricao" align="Left">
                        Cidade
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-3">
                      <label htmlFor="descricao" align="Left">
                        CNPJ
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-sm-3">
                      <label htmlFor="descricao" align="Left">
                        Inscr. Estadual
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-vendedor"
                  role="tabpanel"
                  aria-labelledby="nav-vendedor-tab"
                >
                  Vendedor
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div className="card card-body border-2 shadow table-wrapper table-responsive-md">
              <div className="row mb-5">
                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Produto</th>
                        <th>Qtde</th>
                        <th>Preço un.</th>
                        <th>Sub. Total</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                      <tr>
                        <td>..</td>
                        <td>..</td>
                        <td>.. </td>
                        <td>..</td>
                        <td>..</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card card-footer border-2 shadow table-wrapper table-responsive-md">
              <div className="row md-6">
                <div className="col-md-8">
                  <button
                    id="btnImprimir"
                    className="btn btn-outline-primary esp-bot"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPdv"
                    onClick={false}
                  >
                    {" "}
                    Imprimir
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="30"
                      fill="currentColor"
                      class="bi bi-printer"
                      viewBox="0 0 15 20"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                  </button>

                  <button
                    id="btnCancelar"
                    className="btn btn-outline-danger esp-bot"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPdv"
                    onClick={false}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="30"
                      fill="currentColor"
                      class="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>{" "}
                    Cancelar
                  </button>

                  <button
                    id="btnFinalizar"
                    className="btn btn-outline-success esp-bot"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPdv"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="30"
                      fill="currentColor"
                      class="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>{" "}
                    Finalizar
                  </button>
                </div>

                <div className="col-md-4">
                  <h4 align="Center">Total : R$ 180,00</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Pdv;
