import React, { useState } from "react";
import CadCliente from "./CadCliente";
import ClienteDetail from "./ClienteDetail";

const ClientePage = () => {
  const [viewDetail, setViewDetail] = useState(false);
  const [consumer, setConsumer] = useState({});
  return (
    <>
      {viewDetail ? (
        <ClienteDetail data={consumer} setView={() => setViewDetail(false)} />
      ) : (
        <CadCliente
          setView={(consumer) => [setConsumer(consumer), setViewDetail(true)]}
        />
      )}
    </>
  );
};

export default ClientePage;
