/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import Footer from "../../Footer/Footer";

const MasterLayout = ({ children }) => {
  return (
    <React.Fragment>
      <NavBar />
      <SideBar />
      <div className="content">
        {children}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default MasterLayout;
