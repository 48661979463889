/* eslint-disable eqeqeq */
import {
  formataValorComSimbolo,
  formataValorSemSimbolo,
} from "../../utils/utils";

/**
 * props:
 * - data
 * - onAlterarClick
 * - onExcluirClick
 */
function ItensPedidoCompraRow(props) {
  function alteraClasse() {
    if (props.data.temPreco == true) {
      return "btn btn-info btn-xs ms-2";
    } else {
      return "btn btn-secondary btn-xs ms-2";
    }
  }

  return (
    <tr>
      <td>{props.data.cod_pk_produto}</td>
      <td>{props.data.descricao}</td>
      <td>{props.data.unidade}</td>
      <td>{formataValorSemSimbolo(props.data.qtde)}</td>
      <td>{formataValorComSimbolo(props.data.preco_unitario)}</td>
      <td>
        {
          <button
            id={"alterar" + props.data.cod_itens_pedido_compra}
            type="button"
            className={alteraClasse()}
            title="Informa preço"
            data-bs-toggle="modal"
            data-bs-target="#modalPreco"
            onClick={props.onInformaPrecoClick}
          >
            <svg
              id={"alterar" + props.data.cod_itens_pedido_compra}
              className="icon icon-xs"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id={"alterar" + props.data.cod_itens_pedido_compra}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </button>
        }
      </td>
    </tr>
  );
}

export default ItensPedidoCompraRow;
