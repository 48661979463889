import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const ESTOQUE_URL = `${API_URL}/estoque/`;

export async function getEstoques(descricao, page, token) {

    const estoquesUrl = `${ESTOQUE_URL}${descricao}?page=${page}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(estoquesUrl, { headers });
    return response.data;
}

export async function getEstoqueProduto(cod_pk_produto, token) {

    const headers = { 'authorization': token };
    const response = await axios.get(`${ESTOQUE_URL}${cod_pk_produto}`, { headers });
    return response.data;
}
