/* eslint-disable eqeqeq */

export function formataData(data) {
  var dataParametro = new Date(data);
  var dataFormatada = dataParametro.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });

  return dataFormatada;
}

export function formatDate(date, format) {
  const map = {
    mm: date.getMonth() + 1,
    dd: date.getDate(),
    aa: date.getFullYear().toString().slice(-2),
    aaaa: date.getFullYear(),
  };

  return format.replace(/mm|dd|aa|aaaa/gi, (matched) => map[matched]);
}

export function formataValorComSimbolo(valor) {
  var atual = valor;

  var f = atual.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

  return f;
}

export function formataValorSemSimbolo(valor) {
  var atual = valor;

  var f = atual.toLocaleString("pt-br", { minimumFractionDigits: 2 });

  return f;
}

export function formatarMoeda(event) {
  var valor = event.target.value;

  valor = valor + "";
  valor = parseInt(valor.replace(/[\D]+/g, ""));
  valor = valor + "";
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  event.target.value = valor;
  if (valor == "NaN") event.target.value = 0;

  return valor;
}

export function encriptaBase64(texto) {
  var emBase64 = new Buffer(texto).toString("base64");

  return emBase64;
}

export function decriptaBase64(texto) {
  var deBase64 = new Buffer(texto, "base64").toString("ascii");

  return deBase64;
}

export function formatDateToSql(date) {
  const [day, month, year] = date.split('/');
  return `${year}-${month}-${day}`;
}


