/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../components/authentication/AuthContext";
import { getUrl } from "../../components/helpers/Api";
import "./SearchUrl.scss";
import { Load } from "../../components/widgets/spinner/Spinner.jsx";

function SearchUrl() {
  const { isAuthenticated, setSearchingUrl, searchingUrl } =
    useContext(Context);
  const { url } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUrl(url)
      .then((res) => {
        localStorage.setItem("cod_org", res.data.url);
        setSearchingUrl(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);
  return (
    <div style={{ marginTop: "200px" }}>
      {loading ? (
        <Load />
      ) : (
        <div className="not-found">
          {searchingUrl ? <h1>Página não encontrada 404</h1> : ""}
        </div>
      )}
    </div>
  );
}

export default SearchUrl;
