/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import {
  getFuncionarios,
  excluirFuncionario,
} from "../../services/FuncionarioService";
import CadFuncionarioRow from "./CadFuncionarioRow";
import CadFuncionarioModal from "./CadFuncionarioModal/CadFuncionarioModal";
import PesquisaFuncionario from "./PesquisaFuncionario";

function CadFuncionario() {
  const [pesquisaFuncionarios, setPesquisaFuncionario] = useState("");
  const [funcionarios, setFuncionarios] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const token = localStorage.getItem("token");

  const DEFAULT_FUNCIONARIO = {
    nome_funcionario: "",
    funcao: "Gerente",
    situacao: "Ativo",
  };

  const [editFuncionario, setEditFuncionario] = useState(DEFAULT_FUNCIONARIO);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovaFuncionarioClick(event) {
    setEditFuncionario(DEFAULT_FUNCIONARIO);
  }

  function getFuncionariosCall(token) {
    getFuncionarios(pesquisaFuncionarios, page || 1, token)
      .then((result) => {
        setFuncionarios(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    setPage(getPage(history));
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getFuncionariosCall(token);
  }, [page, pesquisaFuncionarios]);

  function onAlterarClick(event) {
    const cod_funcionario = event.target.id.replace("alterar", "");
    const funcionario = funcionarios.find(
      (e) => e.cod_funcionario == cod_funcionario
    );
    setEditFuncionario({ ...funcionario });
  }

  function onExcluirClick(event) {
    const cod_funcionario = event.target.id.replace("excluir", "");
    excluirFuncionario(cod_funcionario, token)
      .then((funcionario) => {
        setTimeout(function () {
          history(0)
        }, 1000);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    setTimeout(function () {
      history(0)
    }, 1000);
  }

  function onPesquisaFuncionarioChange(event) {
    setPesquisaFuncionario(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Funcionário</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovaFuncionario"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalFuncionario"
                onClick={onNovaFuncionarioClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Novo Funcionário
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaFuncionario
                placeholder="Pesquisa Funcionário"
                onChange={onPesquisaFuncionarioChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Código</th>
                <th className="border-gray-200">Nome Funcionario</th>
                <th className="border-gray-200">Função</th>
                <th className="border-gray-200">Situação</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {funcionarios.map((funcionario) => (
                <CadFuncionarioRow
                  key={funcionario.cod_funcionario}
                  data={funcionario}
                  onAlterarClick={onAlterarClick}
                  onExcluirClick={onExcluirClick}
                />
              ))}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
      </main>
      <CadFuncionarioModal data={editFuncionario} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadFuncionario;
