import React, { createContext, useState, useEffect } from "react";
import {
  doLogin,
  doLoginFornecedor,
  doLogout,
} from "../../services/AuthService";
import { toast } from "react-toastify";
import { setToken } from "../helpers/Api";
import { useNavigate } from "react-router-dom";

const Context = createContext();

const AuthContext = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [usuarioConsumidor, setUsuarioConsumidor] = useState(
    localStorage.getItem("consumerUser") ? JSON.parse(localStorage.getItem("consumerUser")) : ""
  );
  const [enderecoUsuarioConsumidor, setEnderecoUsuarioConsumidor] = useState("");
  const [searchingUrl, setSearchingUrl] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem("token"));
  }, []);

  function handleLogin(email, senha, isSupplier) {
    setLoading(true);
    let cod_org = localStorage.getItem("cod_org");

    const loginFunction = isSupplier ? doLoginFornecedor : doLogin;

    loginFunction(email, senha, cod_org)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("sistemacemail", email);
          localStorage.setItem("sistemacefornecedor", isSupplier ? "sim" : "nao");
          localStorage.setItem("token", res.data.token);
          setToken(res.data.token, cod_org);
          toast.success("Login efetuado com sucesso");
          setIsAuthenticated(res.data.token);
          navigate("/pedidos");  // Redireciona para a rota /pedidos após login
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          toast.error("Usuário ou senha inválidos!");
        } else {
          toast.error("Ocorreu um erro ao efetuar o login. Tente novamente.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleLogout() {
    doLogout(localStorage.getItem("token"))
      .then(() => {
        setIsAuthenticated(null);
        localStorage.removeItem("token");
        localStorage.removeItem("sistemacemail");
        localStorage.removeItem("sistemacefornecedor");
        localStorage.removeItem("cod_org");
        navigate("/login");  // Redireciona para a rota de login após logout
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        handleLogin,
        handleLogout,
        loading,
        usuarioConsumidor,
        setUsuarioConsumidor,
        enderecoUsuarioConsumidor,
        setEnderecoUsuarioConsumidor,
        searchingUrl,
        setSearchingUrl,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { AuthContext, Context };
