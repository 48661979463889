import moment from "moment";
import { toast } from "react-toastify";

export function mask(v) {
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}
export function maskCnpj(v) {
  v = v.replace(/\D/g, "");

  v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");

  return v;
}

export function converteData(data) {
  const dataConvertida = moment(data).format("DD/MM/YYYY, h:mm a");
  return dataConvertida;
}
export function converteData2(data) {
  const dataConvertida = moment(data).format("DD/MM/YYYY");
  return dataConvertida;
}
export function reconverteData(data) {
  const dataConvertida = `${data.slice(-4)}-${data.slice(3, 5)}-${data.slice(
    0,
    2
  )}`;
  return dataConvertida;
}

export function validarData(data) {
  const dateFormatInput = moment(data).format("YYYY-MM-DD");
  const dateFormatToday = moment().format("YYYY-MM-DD");
  const dateInput = dateFormatInput.replace(/[^a-zA-Z0-9]/g, "");
  const dateToday = dateFormatToday.replace(/[^a-zA-Z0-9]/g, "");

  if (dateInput.length === 8) {
    if (dateInput.slice(4, 6) <= dateToday.slice(4, 6)) {
      if (
        dateInput.slice(6, 8) <= "00" ||
        dateInput.slice(6, 8) > "31" ||
        dateInput.slice(6, 8) < dateToday.slice(6, 8)
      ) {
        return "Invalid date";
      }
    }
    if (
      dateInput.slice(4, 6) <= "00" ||
      dateInput.slice(4, 6) > "12" ||
      dateInput.slice(4, 6) < dateToday.slice(4, 6)
    ) {
      return "Invalid date";
    }
    if (dateInput.slice(0, 4) < dateToday.slice(0, 4)) {
      return "Invalid date";
    }
  } else {
    return "Invalid date";
  }
  return false;
}

export function handleDownload(data, name, extension) {
  let filename = `${name.replaceAll(" ", "_")}.${extension}`;
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([data.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

export function trataRespota(type, data) {
  if (type === "error") {
    if (data.response.status === 422) {
      if (data.response.data.errors) {
        let errorValue =
          data.response.data.errors[
            Object.keys(data.response.data.errors)[0]
          ][0];
        toast.error(errorValue);
      }
    } else {
      toast.error(data.response.data.message);
    }
  }
}
