import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("sistemacemail");
      toast.warning("Sessão expirada !!");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      return error;
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
