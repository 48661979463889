import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const TRIB_URL = `${API_URL}/tributacao/`;

export async function getTributacoes(descricao, page, token) {
  
  const codOrg = localStorage.getItem("cod_org");
  const tributacoesUrl = `${TRIB_URL}${descricao}?page=${page}&cod_org=${codOrg}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(tributacoesUrl, { headers });
  return response.data;
}

export async function getTodasTributacoes(token) {
  
  const codOrg = localStorage.getItem("cod_org");
  
  const headers = {
    authorization: token,
  };

  const response = await axios.get(`${TRIB_URL}?cod_org=${codOrg}`, {
    headers,
  });
  return response.data;
}

export async function salvarTributacao(cod_tributacao, novaTributacao, token) {
  const headers = { authorization: token };

  let response;

  if (cod_tributacao) {
    response = await axios.patch(
      `${TRIB_URL}${cod_tributacao}`,
      novaTributacao,
      { headers }
    );
  } else {
    response = await axios.post(`${TRIB_URL}`, novaTributacao, { headers });
  }

  return response.data;
}

export async function excluirTributacao(cod_tributacao, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${TRIB_URL}${cod_tributacao}`, {
    headers,
  });
  return response.data;
}
