import { Box, Modal } from "@mui/material";
import React from "react";
import "./Modals.scss";

const ModalConfirm = ({ ...props }) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onKeyDown={(e) => (e.keyCode === 27 ? props.setModalCrudFalse() : "")}
      >
        <Box className="modal-confirm">
          <div className="modal-content">
            <p id="modal-modal-title" className="title">
              {props.title}
            </p>
            <div className="button-box">
              <button
                className="button-back"
                onClick={() => [props.setModalCrudFalse()]}
              >
                <span>{props.textBack}</span>
              </button>
              <button
                className="button-red"
                onClick={() => props.handleRequest()}
              >
                {!props.loading && (
                  <span className="indicator-label">{props.textSubmit}</span>
                )}
                {props.loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
