import React, { useRef, useState, useEffect } from "react";
import { salvarImpressora } from "../../../services/ImpressoraService";
import { toast } from "react-toastify";
import "./CadImpressoraModal.scss";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadImpressoraModal(props) {
    const [impressora, setImpressora] = useState({
        tipo: "",
        descricao: "",
        tipoConexao: "",
        caminho: "",
        enderecoBluetooth: "",
        guid: "",
        cod_org: localStorage.getItem("cod_org"),
    });
    const btnClose = useRef("");
    const token = localStorage.getItem("token");

    useEffect(() => {
        setImpressora(props.data);
    }, [props.data]);

    function onInputChange(event) {
        setImpressora((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }));
    }

    function onSelectChange(event) {
        setImpressora((prevState) => ({
            ...prevState,
            tipoConexao: event.target.value,
            caminho: event.target.value === "Rede" ? prevState.caminho : "", // Reset caminho se não for Rede
        }));
    }

    function onSubmit(event) {
        event.preventDefault();

        if (!impressora.tipo || !impressora.descricao || !impressora.tipoConexao) {
            return toast.warning("Preencha todos os campos obrigatórios!");
        }

        salvarImpressora(impressora.cod_org, impressora, token)
            .then((result) => {
                Object.keys(impressora).forEach((key) => {
                    impressora[key] = "";
                });

                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
                toast.success("Impressora salva com sucesso!");
            })
            .catch((err) => {
                console.error(err.response ? err.response.data : err.message);
            });
    };

    return (
        <div className="modal fade" id="modalImpressora" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">
                            {props.data.cod_impressora ? "Alterar " : "Nova "} Impressora
                        </p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="tipo" className="required">Tipo</label>
                                    <select className="form-control" id="tipo" value={impressora.tipo} onChange={onInputChange}>
                                        <option value="">Selecione o tipo</option>
                                        <option value="Star">Star</option>
                                        <option value="Epson">Epson</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="descricao" className="required">Descrição</label>
                                    <input className="form-control" id="descricao" type="text" value={impressora.descricao} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="tipoConexao" className="required">Tipo de Conexão</label>
                                    <select className="form-control" id="tipoConexao" value={impressora.tipoConexao} onChange={onSelectChange}>
                                        <option value="">Selecione o tipo de conexão</option>
                                        <option value="USB">USB</option>
                                        <option value="Rede">Rede</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="guid">GUID</label>
                                    <input className="form-control" id="guid" type="text" value={impressora.guid} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="row">
                                {impressora.tipoConexao === "Rede" && (
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="caminho" className="required">Endereço</label>
                                        <input className="form-control" id="caminho" type="text" value={impressora.caminho} onChange={onInputChange} />
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={onSubmit}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadImpressoraModal;
