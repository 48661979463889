import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const PRODUTO_URL = `${API_URL}/produto/`;

export async function getProdutos(descricao, page, token, additional) {
  const codOrg = localStorage.getItem("cod_org");

  const produtosUrl = `${PRODUTO_URL}${descricao}?page=${page}&cod_org=${codOrg}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response.data;
}

export async function getPrecoAVista(cod_produto, token) {
  const produtosUrl = `${PRODUTO_URL}precoavista/${cod_produto}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response.data;
}

export async function getProdutosPesquisa(cod_org, token) {
  const produtosUrl = `${PRODUTO_URL}pesquisa-produtos/${cod_org}`;

  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response.data;
}

export async function getProdutoCodigoBarras(pesquisa, tipoPesquisa, token) {
  const produtosUrl = `${PRODUTO_URL}/pesquisa/${pesquisa}/${tipoPesquisa}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response.data;
}


export async function getProdutosAdicionais(token) {
  const codOrg = localStorage.getItem("cod_org");
  const produtosUrl = `${PRODUTO_URL}add/${codOrg}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response;
}

export async function getProdutosAcompbyId(token, cod_produto) {
  const codOrg = localStorage.getItem("cod_org");
  const produtosUrl = `${PRODUTO_URL}add/${codOrg}/${cod_produto}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(produtosUrl, { headers });
  return response;
}

export async function salvarProduto(cod_produto, novoProduto, token) {
  const headers = { authorization: token };

  let response;

  if (cod_produto) {
    response = await axios.patch(`${PRODUTO_URL}${cod_produto}`, novoProduto, {
      headers,
    });
  } else {
    response = await axios.post(`${PRODUTO_URL}`, novoProduto, { headers });
  }

  return response.data;
}

export async function excluirProduto(cod_produto, token, pathImage) {
  const headers = { authorization: token };
  const response = await axios.delete(
    `${PRODUTO_URL}${cod_produto}?path_image=${pathImage}&cod_org=${localStorage.getItem(
      "cod_org"
    )}`,
    {
      headers,
    }
  );
  return response.data;
}
