import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

export async function getParamger(token) {
    const paramgerUrl = `${API_URL}/paramger`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(paramgerUrl, { headers });
    return response.data;
}
