// import axios from './BaseService';
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const EMPRESA_URL = `${API_URL}/empresa/`;

export async function getEmpresas(page, token) {
  
  const codOrg = localStorage.getItem("cod_org");  

  const empresasUrl = `${EMPRESA_URL}?page=${page}&cod_org=${codOrg}`;
  const headers = {
    authorization: token,
  };

  const response = await axios.get(empresasUrl, { headers });
  return response.data;
}

export async function salvarEmpresa(cod_empresa, novaEmpresa, token) {
  const headers = { authorization: token };

  let response;

  if (cod_empresa) {
    response = await axios.patch(`${EMPRESA_URL}${cod_empresa}`, novaEmpresa, {
      headers,
    });
  } else {
    response = await axios.post(`${EMPRESA_URL}`, novaEmpresa, { headers });
  }

  return response.data;
}

export async function excluirEmpresa(cod_empresa, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${EMPRESA_URL}${cod_empresa}`, {
    headers,
  });
  return response.data;
}
