/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useRef, useState, useEffect } from "react";
import { salvarProduto } from "../../../../services/ProdutoService";
import { getSecoes, getTodasSecoes } from "../../../../services/SecaoService";
import { getTodasTributacoes } from "../../../../services/TributacaoService";
import { getEstoqueProduto } from "../../../../services/EstoqueService";
import SwitchInput from "./SwitchInput";
import {
  formatarMoeda,
  formataValorSemSimbolo,
  formataValorComSimbolo,
} from "../../../../utils/utils";
import { toast } from "react-toastify";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadProdutosModal(props) {
  const [secao, setSecao] = useState(["CARREGANDO"]);
  const [tributacao, setTributacao] = useState(["CARREGANDO"]);
  const [erro, setError] = useState("");
  const [estoque, setEstoque] = useState({});
  const [produto, setProduto] = useState({
    situacao: "Ativo",
    preco_venda_vista: 0.0,
  });
  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");
  const [file, setFile] = useState("");

  useEffect(() => {
    setProduto(props.data);
  }, [props.data]);

  useEffect(() => {
    getSecoes("", 1, null, "all", "S")
      .then((res) => {
        setSecao(res.rows);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  useEffect(() => {
    getTodasTributacoes(token)
      .then((result) => {
        setTributacao(result.rows);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  function onSubmit(event) {
    produto.cod_org = localStorage.getItem("cod_org");

    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    }
    if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }
    if (produto.descricao.length < 3) {
      return toast.warning("Insira um nome para o seu produto !!");
    }
    if (!produto.cod_fk_secao) {
      return toast.warning("Selecione a seção do seu produto !!");
    }
    // if (!produto.preco_venda_vista) {
    //   return toast.warning("Insira o valor do seu produto !!");
    // }
    if (!produto.cod_fk_tributacao) {
      return toast.warning("Selecione a tributação do seu produto !!");
    }

    const formData = new FormData();
    formData.append("imagem", file);
    formData.append("descricao", produto.descricao);
    formData.append("cod_fk_secao", produto.cod_fk_secao);
    formData.append("listar_secao", secao.listar_secao);
    formData.append("cod_org", produto.cod_org);
    formData.append("preco_venda_vista", produto.preco_venda_vista || 0);
    formData.append("situacao", produto.situacao);
    formData.append("old_path_image", produto.imagem);
    formData.append("cod_fk_tributacao", produto.cod_fk_tributacao);
    formData.append("tipo", produto.tipo || "P");
    formData.append("unidade", produto.unidade || "UN");
    formData.append("web_adicional", true);
    formData.append("additionals", JSON.stringify([]));
    formData.append("deleted_ids", JSON.stringify([]));
    formData.append("old_section", props.data.cod_fk_secao);

    if (produto.cod_barras !== undefined) {
      formData.append("cod_barras", produto.cod_barras);
    }
    if (produto.cod_fabricante !== undefined) {
      formData.append("cod_fabricante", produto.cod_fabricante);
    }
    if (produto.marca !== undefined) {
      formData.append("marca", produto.marca);
    }

    formData.append("cod_balanca", produto.cod_balanca || 0);
    formData.append("preco_custo", produto.preco_custo || 0);
    formData.append("preco_custo_liquido", produto.preco_custo_liquido || 0);
    formData.append("taxa_remarcacao", produto.taxa_remarcao || 0);
    formData.append("est_minimo", produto.est_minimo || 0);
    formData.append("embalagem", produto.embalagem || 0);
    formData.append("preco_venda_atacado", produto.preco_venda_atacado || 0);

    if (produto.ncm !== undefined) {
      formData.append("ncm", produto.ncm);
    }
    if (produto.locacao !== undefined) {
      formData.append("locacao", produto.locacao);
    }
    if (produto.aplicacao !== undefined) {
      formData.append("aplicacao", produto.aplicacao);
    }
    if (produto.anp !== undefined) {
      formData.append("cod_anp", produto.anp);
    }
    if (produto.cest !== undefined) {
      formData.append("cest", produto.cest);
    }
    if (produto.cod_barras_emb !== undefined) {
      formData.append("cod_barras_emb", produto.cod_barras_emb);
    }
    formData.append("est_promo_emp", produto.est_promo_emp);

    salvarProduto(produto.cod_produto, formData, token)
      .then((result) => {
        produto.descricao = "";
        produto.tipo = "";
        produto.cod_fk_secao = "";
        produto.unidade = "";
        produto.preco_venda_vista = "";
        produto.cod_fk_tributacao = "";
        produto.listar_secao = "";
        produto.cod_barras = "";
        produto.cod_fabricante = "";
        produto.preco_custo = "";
        produto.preco_custo_liquido = "";
        produto.taxa_remarcacao = "";
        produto.situacao = "";
        produto.marca = "";
        produto.locacao = "";
        produto.ncm = "";
        produto.aplicacao = "";
        produto.cod_anp = "";
        produto.cest = "";
        produto.cod_barras_emb = "";
        produto.est_promo_emp = "";
        produto.cod_balanca = "";
        produto.est_minimo = "";
        produto.embalagem = "";
        produto.preco_venda_atacado = "";

        document.getElementById("formFile").value = "";
        document.getElementById("descricao").value = "";
        document.getElementById("preco_venda_vista").value = "";

        btnClose.current.click();
        if (props.onSubmit) props.onSubmit(result);
        if (produto.cod_produto) {
          toast.success("Produto alterado com sucesso !!");
        } else {
          toast.success("Produto criado com sucesso !!");
        }
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.response ? err.response.data : err.message);
      });

    props.onSubmit(event);
  }
  function onInputChange(event) {
    if (
      event.target.id === "preco_custo" ||
      event.target.id === "preco_custo_liquido" ||
      event.target.id === "taxa_remarcacao" ||
      event.target.id === "preco_venda_vista" ||
      event.target.id === "est_minimo" ||
      event.target.id === "embalagem" ||
      event.target.id === "preco_venda_atacado"
    ) {
      setError("");

      let valor = formatarMoeda(event);

      valor = valor.replace(".", "");
      valor = valor.replace(",", ".");

      if (valor > 0) {
        setProduto((prevState) => ({ ...prevState, [event.target.id]: valor }));
      }
    } else {
      setProduto((prevState) => ({
        ...prevState,
        [event.target.id]: event.target.value,
      }));
    }
  }

  useEffect(() => {
    if (produto.cod_produto) {
      getEstoqueProduto(produto.cod_produto, token)
        .then((result) => {
          setEstoque(result);
        })
        .catch((err) => {
          console.error(err.response ? err.response.data : err.message);
        });
    }
  }, [produto]);

  return (
    <div
      className="modal fade bd-example-modal-lg"
      id="modalProduto"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true"
    >
      <script
        type="text/javascript"
        src="https://rawgit.com/RobinHerbots/jquery.inputmask/3.x/dist/jquery.inputmask.bundle.js"
      ></script>

      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {props.data.cod_mesa ? "Alterar " : "Novo "}Acompnhamento
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 text-center">
                  <label htmlFor="formFile" className="form-label">
                    Imagem Produto
                  </label>
                  <img
                    className="img-thumbnail imgpx200"
                    alt="..."
                    crossOrigin="anonymous"
                    src={
                      props?.data?.imagem
                        ? process.env.REACT_APP_API_URL +
                        `/${props.data.imagem}`
                        : "/img/image.png"
                    }
                  />
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    accept="image/*"
                    id="formFile"
                    onChange={(e) => setFile(e.target.files[0])}
                    multiple
                  />
                </div>

                <div className="col-md-9 mb-3">
                  <div className="row">
                    <div className="col-md-7 mb-3">
                      <label
                        htmlFor="descricao"
                        align="Left"
                        className="required"
                      >
                        Nome do Acompnhamento
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="Informe a Descrição"
                        defaultValue={produto.descricao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="tipo" align="Left" className="required">
                        Tipo Produto
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.tipo}
                        name="tipo"
                        id="tipo"
                        onChange={onInputChange}
                      >
                        <option value="P">Produto</option>
                        <option value="S">Serviço</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <div className="form-group">
                        <label htmlFor="descricao" align="Left">
                          Situação
                        </label>
                        <SwitchInput
                          id="situacao"
                          text={produto.situacao}
                          onChange={onInputChange}
                          isChecked={produto.situacao}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="secao" align="Left" className="required">
                        Seção
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.cod_fk_secao}
                        name="cod_fk_secao"
                        id="cod_fk_secao"
                        onChange={onInputChange}
                      >
                        <option value="">Selecione...</option>
                        {secao.map((s) => (
                          <option
                            key={"secao" + s.cod_secao}
                            value={s.cod_secao}
                          >
                            {s.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_barras" align="Left">
                        Codigo Barras
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_barras"
                        type="text"
                        defaultValue={produto.cod_barras}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_fabricante" align="Left">
                        Codigo Fabricante
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_fabricante"
                        type="text"
                        defaultValue={produto.cod_fabricante}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_balanca" align="Left">
                        Codigo Balança
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_balanca"
                        type="text"
                        defaultValue={produto.cod_balanca}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <label
                        htmlFor="descricao"
                        align="Left"
                        className="required"
                      >
                        Unidade
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.unidade}
                        name="unidade"
                        id="unidade"
                        onChange={onInputChange}
                      >
                        <option value="UN">UN</option>
                        <option value="KG">KG</option>
                        <option value="MT">MT</option>
                        <option value="PC">PC</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="preco_custo" align="Left">
                        Custo
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_custo"
                        type="text"
                        defaultValue={produto.preco_custo}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="preco_custo_liquido" align="Left">
                        Custo Liquido
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_custo_liquido"
                        type="text"
                        defaultValue={produto.preco_custo_liquido}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="taxa_remarcacao" align="Left">
                        % Lucro
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="taxa_remarcacao"
                        type="text"
                        defaultValue={produto.taxa_remarcacao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label
                        htmlFor="preco_venda_vista"
                        align="Left"
                        className="required"
                      >
                        Preço de Venda
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_venda_vista"
                        type="text"
                        defaultValue={produto.preco_venda_vista}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-dados-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dados"
                    type="button"
                    role="tab"
                    aria-controls="nav-dados"
                    aria-selected="true"
                  >
                    Dados
                  </button>
                  <button
                    className="nav-link"
                    id="nav-estoque-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-estoque"
                    type="button"
                    role="tab"
                    aria-controls="nav-estoque"
                    aria-selected="false"
                  >
                    Estoque
                  </button>
                  <button
                    className="nav-link"
                    id="nav-trib-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-trib"
                    type="button"
                    role="tab"
                    aria-controls="nav-trib"
                    aria-selected="false"
                  >
                    Tributação
                  </button>
                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-dados"
                  role="tabpanel"
                  aria-labelledby="nav-dados-tab"
                >
                  <br></br>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="marca" align="Left">
                        Marca
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="marca"
                        type="text"
                        defaultValue={produto.marca}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="locacao" align="Left">
                        Locação
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="locacao"
                        type="text"
                        defaultValue={produto.locacao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="est_minimo" align="Left">
                        Estoque Minimo
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_minimo"
                        type="text"
                        defaultValue={produto.est_minimo}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cod_barras_emb" align="Left">
                        Codigo Barras Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_barras_emb"
                        type="text"
                        defaultValue={produto.cod_barras_emb}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="embalagem" align="Left">
                        Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="embalagem"
                        type="text"
                        defaultValue={produto.embalagem}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="preco_venda_atacado" align="Left">
                        Preço Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_venda_atacado"
                        type="text"
                        defaultValue={produto.preco_venda_atacado}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="aplicacao" align="Left">
                        Aplicação
                      </label>
                      <textarea
                        className="form-control form-control-sm"
                        id="aplicacao"
                        rows="3"
                        defaultValue={produto.aplicacao}
                        onChange={onInputChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-estoque"
                  role="tabpanel"
                  aria-labelledby="nav-estoque-tab"
                >
                  <br></br>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_real" align="Left">
                        Estoque Real
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_real_emp"
                        type="number"
                        disabled
                        defaultValue={estoque.est_real_emp}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_fiscal" align="Left">
                        Estoque Fiscal
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_fiscal_emp"
                        type="number"
                        disabled
                        defaultValue={estoque.est_fiscal_emp}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_promo" align="Left">
                        Estoque Promocional
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_promo_emp"
                        type="number"
                        disabled
                        defaultValue={produto.est_promo_emp}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-trib"
                  role="tabpanel"
                  aria-labelledby="nav-trib-tab"
                >
                  <br></br>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="tributacao"
                        align="Left"
                        className="required"
                      >
                        Tributação
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.cod_fk_tributacao}
                        name="cod_fk_tributacao"
                        id="cod_fk_tributacao"
                        onChange={onInputChange}
                      >
                        <option value="">Selecione...</option>
                        {tributacao.map((t) => (
                          <option
                            key={"tributacao" + t.cod_tributacao}
                            value={t.cod_tributacao}
                          >
                            {t.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="ncm" align="Left">
                        NCM
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="ncm"
                        type="text"
                        defaultValue={produto.ncm}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cest" align="Left">
                        CEST
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cest"
                        type="text"
                        defaultValue={produto.cest}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="anp" align="Left">
                        ANP
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="anp"
                        type="text"
                        defaultValue={produto.anp}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {erro ? (
              <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <button
              ref={btnSalvar}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onSubmit}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadProdutosModal;
