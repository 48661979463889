import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const PRECO_URL = `${API_URL}/precoipedidocompra/`;

export async function informaPreco(novoPreco, token) {

    const headers = { 'authorization': token }

    let response;

    response = await axios.post(`${PRECO_URL}`, novoPreco, { headers });
    return response.data;
}

export async function temPreco(cod_itens_pedido_compra, cod_pk_fornecedor, token) {

    const precoUrl = `${PRECO_URL}${cod_itens_pedido_compra}/${cod_pk_fornecedor}`;
    const headers = {
        'authorization': token
    }
    let response;
    response = await axios.get(precoUrl, { headers });
    return response.data;
}