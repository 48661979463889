/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "./Pedidos.scss";
import PedidosAbertos from "./PedidosAbertos";
import CriarPedidos from "./CriarPedidos";
import { useParams, useNavigate } from "react-router-dom";
import FecharPedidos from "./fechar-pedido/FecharPedidos";

function Pedidos() {
  const [createView, setCreateView] = useState(false);
  const [closeOrderView, setCloseOrderView] = useState(false);
  const [data, setData] = useState({});
  const { create } = useParams();
  const history = useNavigate();

  useEffect(() => {
    if (create === "criar") {
      setData(null);
      setCreateView(true);
    } else {
      setCreateView(false);
    }
  }, [create]);

  function handleViewOrder() {
    history("/pedidos");
    setTimeout(() => {
      setCreateView(false);
    }, 100);
  }

  return (
    <div className="orders">
      {closeOrderView ? (
        <FecharPedidos setClose={() => setCloseOrderView(false)} data={data} />
      ) : (
        <>
          {createView ? (
            <CriarPedidos backView={() => handleViewOrder()} data={data} />
          ) : (
            <PedidosAbertos
              setCreate={(data) => [setData(data), setCreateView(true)]}
              setClose={(data) => [setData(data), setCloseOrderView(true)]}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Pedidos;
