import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import { toast } from "react-toastify";
import { createConsumer, createAddressConsumer } from "../../../helpers/Api";

const NewConsumerModal = ({ open, onClose, onSave }) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [street, setStreet] = useState("");
    const [complement, setComplement] = useState("");
    const [numberHouse, setNumberHouse] = useState("");
    const [noNumber, setNoNumber] = useState(false);
    const [reference, setReference] = useState("");
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState("");

    async function handleCreateConsumer() {
        if (!name || !phone) {
            toast.error("Nome e telefone são obrigatórios");
            return;
        } else if (neighborhood?.length < 1) {
            toast.error("Insira o seu bairro");
            return;
        } else if (street?.length < 1) {
            toast.error("Insira sua rua");
            return;
        } else if (numberHouse?.length < 1 && !noNumber) {
            toast.error("Insira o número da sua casa");
            return;
        } else if (city?.length < 1 && !noNumber) {
            toast.error("Insira sua cidade");
            return;
        } else if (numberHouse?.length > 10) {
            return;
        }

        setLoading(true);

        const newConsumer = {
            nome: name,
            telefone: phone,
            bairro: neighborhood,
            rua: street,
            numero: numberHouse,
            complemento: complement,
            referencia: reference,
            cidade: city,
        };

        try {
            const consumerResponse = await createConsumer(newConsumer);
            newConsumer["cod_usuario_consumidor"] = consumerResponse.data.cod_usuario_consumidor;
            newConsumer["telefone_usuario_consumidor"] = newConsumer.telefone;
            const addressResponse = await createAddressConsumer(newConsumer);

            onSave(addressResponse.data);

            toast.success("Cliente e endereço criados com sucesso!!");
        } catch (error) {
            console.error(error);
            toast.error("Erro ao cadastrar cliente, verifique os dados e tente novamente!!");
        } finally {
            setLoading(false);
            onClose();
        }
    }


    return (
        <Modal open={open} onClose={onClose}>
            <Box id="box-mui-add-address" className="">
                <div className="address-modal">
                    <label className="mt-3">Adicionar Novo Cliente</label>
                    <div className="form-control address-content">
                        <label className="mt-2">Nome</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <label className="mt-2">Telefone</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <label className="mt-2">Rua</label>
                        <input
                            type="text"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />

                        <label className="mt-2">Cidade</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />

                        <label className="mt-2">Número</label>
                        <input
                            type="text"
                            value={numberHouse}
                            onChange={(e) => setNumberHouse(e.target.value)}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={noNumber}
                            maxLength={10}
                        />

                        <label className="mt-2">Bairro</label>
                        <input
                            type="text"
                            value={neighborhood}
                            onChange={(e) => setNeighborhood(e.target.value)}
                        />

                        <div className="d-flex mt-2 mb-2">
                            <div
                                className="check-box-number"
                                onClick={() => [setNumberHouse(""), setNoNumber(!noNumber)]}
                                role="button"
                            >
                                {noNumber ? (
                                    <i
                                        className="fa-solid fa-check text-white fs-6"
                                        style={{ margin: "0 3px" }}
                                    ></i>
                                ) : null}
                            </div>
                            <p style={{ margin: "auto 5px", fontWeight: "600" }}>
                                Sem Número
                            </p>
                        </div>

                        <label className="mt-2">Complemento</label>
                        <input
                            type="text"
                            value={complement}
                            onChange={(e) => setComplement(e.target.value)}
                            placeholder="Ex: Lt 12"
                        />

                        <div className="mt-3">
                            <div className="d-flex justify-content-between ">
                                <label>Referência</label>
                                <span>{reference?.length}/150</span>
                            </div>
                            <textarea
                                value={reference}
                                onChange={(e) => setReference(e.target.value)}
                                maxLength={150}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                rows="3"
                            ></textarea>
                        </div>
                    </div>
                    <div className="box-button">
                        <button className="back" onClick={onClose}>
                            Voltar
                        </button>
                        <button
                            disabled={loading}
                            className="save"
                            onClick={handleCreateConsumer}
                        >
                            {!loading && <span className="indicator-label">Salvar</span>}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default NewConsumerModal;
