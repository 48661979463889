import React from 'react';

const FechamentoPrint = React.forwardRef(({ pedido }, ref) => {
    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) return "Data Inválida";
        return new Date(date).toLocaleDateString();
    };

    const formatTime = (time) => {
        if (!time || isNaN(new Date(time))) return "Hora Inválida";
        return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const currentDate = formatDate(new Date());
    const currentTime = formatTime(new Date());

    const styles = {
        printerTicket: {
            display: 'table',
            width: '100%',
            maxWidth: '400px',
            fontWeight: 'lighter',
            lineHeight: '1.3em',
            fontFamily: 'Tahoma, Geneva, sans-serif',
            fontSize: '10px',
            color: 'black',
            padding: '3px 8px'
        },
        title: {
            fontSize: '1.5em',
            padding: '15px 0',
            textAlign: 'center'
        },
        thTd: {
            borderBottom: '1px dashed #BCBCBC',
            padding: '5px 0'
        },
        ttu: {
            textTransform: 'uppercase'
        },
        sup: {
            paddingTop: '10px',
            borderTop: '1px dashed #BCBCBC'
        },
        center: {
            textAlign: 'center'
        },
        itemRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 0'
        },
        itemDescription: {
            flex: 1,
            textAlign: 'left'
        },
        itemPrice: {
            minWidth: '50px',
            textAlign: 'right'
        }
    };

    return (
        <div ref={ref} style={styles.printerTicket}>
            {pedido ? (
                <>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ ...styles.title, ...styles.thTd }} colSpan="3">
                                    {pedido.nome_empresa || 'Victor Shop'}
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="3" style={styles.thTd}>{currentDate} - {currentTime}</th>
                            </tr>
                            <tr>
                                <th colSpan="3" style={styles.thTd}>
                                    {pedido.nome_cliente || 'Nome do cliente'} <br />
                                    {pedido.cpf || '000.000.000-00'}
                                </th>
                            </tr>
                            <tr>
                                <th style={{ ...styles.ttu, ...styles.thTd }} colSpan="3">
                                    <b>Cupom não fiscal</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td colSpan="3" style={styles.itemRow}>
                                            <span style={styles.itemDescription}>{item.descricao}</span>
                                            <span style={styles.itemPrice}>R${(item.valor_unitario * item.quantidade).toFixed(2)}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" style={styles.itemRow}>
                                            <span style={styles.itemDescription}>V.UNIT: R${item.valor_unitario.toFixed(2)}</span>
                                            <span style={styles.itemPrice}>Qtd: {item.quantidade}</span>
                                        </td>
                                    </tr>
                                    {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                        <tr key={acompIndex}>
                                            <td colSpan="3" style={{ paddingLeft: '10px' }}>- {acomp.descricao}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{ ...styles.sup, ...styles.thTd }} colSpan="3"><b>Totais</b></td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Sub-total</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_produtos.toFixed(2)}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Taxa de serviço</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_servico.toFixed(2)}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Total</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ ...styles.sup, ...styles.thTd }} colSpan="3"><b>Pagamentos</b></td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Dinheiro</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_pago ? pedido.valor_pago.toFixed(2) : '0.00'}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Troco</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${(pedido.valor_pago ? pedido.valor_pago - pedido.valor_total : 0).toFixed(2)}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <div style={{ ...styles.center, marginTop: '10px' }}>
                        <p>Pedido: {pedido.numero_pedidomesa}</p>
                        <p>www.site.com</p>
                    </div>
                </>
            ) : (
                <p>Carregando...</p>
            )}
        </div>
    );
});

export default FechamentoPrint;
