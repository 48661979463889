import React, { useRef, useState, useEffect } from "react";
import { salvarSecao } from "../../../../services/SecaoService";
import SwitchInput from "./SwitchInput";
import Toast from "../../../../components/Toast/Toast";
import { toast } from "react-toastify";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadSecoesModal(props) {
  const [notification, setNotification] = useState({ type: "", text: "" });
  const [erro, setErro] = useState("");
  const [secao, setSecao] = useState({
    listar_secao: "Ativo",
    comissao: 0,
  });

  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");
  const [file, setFile] = useState("");
  const [amount, setAmount] = useState("1");

  useEffect(() => {
    setSecao(props.data);
    setAmount(props.data.web_quantidade);
  }, [props.data]);

  function onSubmit(event) {
    secao.cod_org = localStorage.getItem("cod_org");
    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    } else if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }

    if (secao.descricao.length < 3) {
      return toast.warning("Insira um nome para sua seção !!");
    }

    const formData = new FormData();
    formData.append("imagem", file);
    formData.append("descricao", secao.descricao);
    formData.append("comissao", secao.comissao || 0);
    formData.append("listar_secao", secao.listar_secao);
    formData.append("acompanhamento", "S");
    formData.append("cod_org", secao.cod_org);
    formData.append("old_path_image", secao.imagem);
    formData.append("quantidade", amount);

    salvarSecao(secao.cod_secao, formData, token)
      .then((result) => {
        setFile("");
        secao.descricao = "";
        document.getElementById("imagem").value = "";
        setNotification({
          type: "success",
          text: "Registro gravado com sucesso!",
        });

        btnClose.current.click();

        if (props.onSubmit) props.onSubmit(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setErro(err.response ? err.response.data : err.message);
      });
  }
  function isNumber(number) {
    const caracteres = number.split("");
    let newCaracteres = "";
    caracteres.forEach((item) => {
      return isNaN(parseFloat(item)) ? "" : (newCaracteres += item);
    });
    document.getElementById("amount").value = newCaracteres;
    setAmount(newCaracteres);
  }
  function onInputChange(event) {
    setSecao((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  function onKeyUp(event) {
    console.log(formatReal(event.target.value));
  }

  function formatReal(int) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }
  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="modalSecao"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTitleNotify"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="modalTitleNotify">
                {props.data.cod_secao ? "Alterar " : "Nova "}Seção
              </p>
              <button
                ref={btnClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="imagem" align="Left">
                      Imagem da seção
                    </label>
                    <input
                      className="form-control"
                      id="imagem"
                      type="file"
                      multiple
                      required
                      accept="image/*"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="descricao"
                      align="Left"
                      className="required"
                    >
                      Nome da Seção
                    </label>
                    <input
                      className="form-control"
                      id="descricao"
                      type="text"
                      placeholder="Informe a Descrição"
                      value={secao.descricao}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="form-group">
                      <label htmlFor="comissao">Comissão</label>
                      <div className="input-group">
                        <button
                          type="button"
                          className="btn btn-secondary d-inline-flex align-items-center"
                        >
                          <svg
                            className="icon icon-xs"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <input
                          id="comissao"
                          onKeyUp={onKeyUp}
                          type="number"
                          className="form-control"
                          placeholder="Informe a Comissão"
                          defaultValue={secao.comissao}
                          onChange={onInputChange}
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="situacao" align="Left">
                        Situação
                      </label>
                      <SwitchInput
                        id="listar_secao"
                        text="Ativo?"
                        onChange={onInputChange}
                        isChecked={secao.listar_secao}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="descricao" align="Left" className="required">
                    Quantidade
                  </label>
                  <input
                    id="amount"
                    className="form-control"
                    type="text"
                    defaultValue={amount}
                    onChange={(e) => isNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {erro ? (
                <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <button
                ref={btnSalvar}
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onSubmit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default CadSecoesModal;
