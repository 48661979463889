import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const FUNCIONARIO_URL = `${API_URL}/funcionario/`;

export async function getFuncionarios(nome_funcionario, page, token) {

    const funcionariosUrl = `${FUNCIONARIO_URL}${nome_funcionario}?page=${page}`;
    const headers = {
        'authorization': token
    }

    const response = await axios.get(funcionariosUrl, { headers });
    return response.data;
}

export async function salvarFuncionario(cod_funcionario, novaFuncionario, cod_org, token) {

    const headers = { 'authorization': token }

    let response;

    if (cod_funcionario) {
        response = await axios.patch(`${FUNCIONARIO_URL}${cod_funcionario}`, novaFuncionario, { headers });
    } else {
        let url = `${API_URL}/funcionario/${cod_org}`
        console.log(url)
        response = await axios.post(url, novaFuncionario, { headers });
    }

    return response.data;
}

export async function excluirFuncionario(cod_funcionario, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${FUNCIONARIO_URL}${cod_funcionario}`, { headers });
    return response.data;
};

export async function getVendedores(cod_org, token) {
    const headers = { 'authorization': token };
    const vendedores = await axios.get(`${FUNCIONARIO_URL}${cod_org}/vendedores`, { headers })
    return vendedores.data

}