import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const ITENSPEDIDOCOMPRA_URL = `${API_URL}/itenspedidocompra/`;

export async function getItensPedidoCompra(cod_pedido_compra, token) {

    const headers = { 'authorization': token };
    const response = await axios.get(`${ITENSPEDIDOCOMPRA_URL}${cod_pedido_compra}`, { headers });
    return response.data;
}