/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getPedidoCompras } from "../../services/PedidoCompraService";
import PedidoCompraRow from "./PedidoCompraRow";
import PesquisaPedidoCompra from "./PesquisaPedidoCompra";
import { getParceiroPorEmail } from "../../services/ParceiroService";

function PedidoCompra() {
  const [pesquisaPedidoCompra, setPesquisaPedidoCompra] = useState("");
  const [pedidoCompras, setPedidoCompras] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const [editPedidoCompra, setEditPedidoCompra] = useState([]);
  const [codParceiro, setCodParceiro] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const email = localStorage.getItem("sistemacemail");

    getParceiroPorEmail(email, token)
      .then((response) => {
        if (response) {
          setCodParceiro(response.cod_parceiro);
        } else {
          setCodParceiro(0);
        }
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function getPedidoComprasCall(token) {
    getPedidoCompras(pesquisaPedidoCompra, page || 1, token)
      .then((result) => {
        if (codParceiro > 0) {
          let i;
          let x;

          for (i = 0; i < result.rows.length; i++) {
            let achou = false;
            for (x = 0; x < result.rows[i].tb_forn_pedido_compras.length; x++) {
              if (
                result.rows[i].tb_forn_pedido_compras[x].cod_pk_fornecedor ==
                codParceiro
              ) {
                achou = true;
              }
            }

            if (!achou) {
              result.rows.splice(i, 1);
            }
          }
        }

        setPedidoCompras(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    setPage(getPage(history));
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getPedidoComprasCall(token);
  }, [page, pesquisaPedidoCompra, codParceiro]);

  function onPesquisaPedidoCompraChange(event) {
    setPesquisaPedidoCompra(event.target.value);
  }

  function onAlterarClick(event) {
    const cod_pedido_compra = event.target.id.replace("alterar", "");
    const pedido_compra = pedidoCompras.find(
      (e) => e.cod_pedido_compra == cod_pedido_compra
    );
    setEditPedidoCompra({ ...pedido_compra });

    history("/pedidocompradetalhes/" + cod_pedido_compra);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Pedido de Compra</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaPedidoCompra
                placeholder="Pesquisa Pedido Compra"
                onChange={onPesquisaPedidoCompraChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Código</th>
                <th className="border-gray-200">Data</th>
                <th className="border-gray-200">Número</th>
                <th className="border-gray-200">Fornecedor</th>
                <th className="border-gray-200">Total</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {pedidoCompras.map((pedidoCompra) => (
                <PedidoCompraRow
                  key={pedidoCompra.cod_pedido_compra}
                  data={pedidoCompra}
                  onAlterarClick={onAlterarClick}
                />
              ))}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
      </main>
    </React.Fragment>
  );
}

export default PedidoCompra;
