/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useState } from "react";
import "./PaymentMethodModal.scss";
import { Box, Modal } from "@mui/material";

const PaymentMethodModal = ({ ...props }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  return (
    <main>
      <Modal open={props.open} onClose={() => []}>
        <Box className="consumer-modal">
          <section className="first-section">
            <div className="title-content">
              <p className="title-modal">
                {selectedPaymentMethod ? "Endereços do" : ""} Formas de
                Pagamento
              </p>
              <i
                className="fa-solid fa-xmark"
                role="button"
                onClick={() => props.setFalse()}
              ></i>{" "}
            </div>
            {}
          </section>
          <section className="second-section">
            {props.data.map((item, index) => (
              <div
                className="consumer-item"
                key={item.cod_forma_pgto}
                onClick={() => [
                  props.setDataPaymentMthod(item),
                  props.setIndex(index),
                ]}
              >
                {item.descricao}
              </div>
            ))}
          </section>

          {/* Consumer Modal */}
        </Box>
      </Modal>
    </main>
  );
};

export default PaymentMethodModal;
