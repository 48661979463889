import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from 'react-to-print';
import { Box, Modal, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Receipt from "../../../utils/CozinhaPrint";
import FechamentoPrint from "../../../utils/FechamentoPrint";
import EntregaPrint from "../../../utils/EntregaPrint";

const PrintContent = React.forwardRef(({ pedido, option }, ref) => {
    switch (option) {
        case 'cozinha':
            return <Receipt ref={ref} pedido={pedido} />;
        case 'fechamento':
            return <FechamentoPrint ref={ref} pedido={pedido} />;
        case 'entrega':
            return <EntregaPrint ref={ref} pedido={pedido} />;
        default:
            return null;
    }
});

const ModalPrint = ({ open, setModalCrudFalse, pedido }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const receiptRef = useRef();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (open) {
            setSelectedOption(""); // Reset selected option when modal opens
        }
    }, [open]);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    };

    const modalContentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '300px',
        textAlign: 'center',
    };

    const buttonBoxStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    };

    const backButtonStyle = {
        padding: '10px 20px',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const printButtonStyle = {
        padding: '10px 20px',
        backgroundColor: 'green',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    return (
        <Modal
            open={open}
            onClose={setModalCrudFalse}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyDown={(e) => (e.keyCode === 27 ? setModalCrudFalse() : "")}
        >
            <Box sx={style}>
                <div style={modalContentStyle}>
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Escolha uma Opção</InputLabel>
                        <Select
                            labelId="select-label"
                            value={selectedOption}
                            label="Escolha uma opção"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="cozinha">Impressão para Cozinha</MenuItem>
                            <MenuItem value="fechamento">Impressão de Fechamento</MenuItem>
                            <MenuItem value="entrega">Impressão para Entrega</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={buttonBoxStyle}>
                        <button
                            style={backButtonStyle}
                            onClick={setModalCrudFalse}
                        >
                            Cancelar
                        </button>
                        <ReactToPrint
                            trigger={() => (
                                <button style={printButtonStyle}>
                                    Imprimir
                                </button>
                            )}
                            content={() => receiptRef.current}
                        />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <PrintContent ref={receiptRef} pedido={pedido} option={selectedOption} />
                </div>
            </Box>
        </Modal>
    );
};

export default ModalPrint;
