import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { handleCreateTamanho, updateTamanho } from "../../../services/TamanhoService";

function CadSecaoTamanho({ openModal, secao, data, onSubmit, onClose }) {
    const [tamanho, setTamanho] = useState({
        descricao: "",
        cod_org: localStorage.getItem("cod_org"),
        cod_secao: secao?.cod_secao
    });

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (data) {
            setTamanho(data);
        } else {
            setTamanho({
                descricao: "",
                cod_org: localStorage.getItem("cod_org"),
                cod_secao: secao?.cod_secao
            });
        }
    }, [data, secao]);

    function onInputChange(event) {
        const { name, value } = event.target;
        setTamanho((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    function onSubmitForm(event) {
        event.preventDefault();

        if (!tamanho.descricao) {
            return toast.warning("Preencha todos os campos obrigatórios!");
        }

        const saveFunction = handleCreateTamanho;

        let novoTamanho = {
            descricao: tamanho.descricao, cod_org: localStorage.getItem("cod_org"), cod_secao: secao
        }
        saveFunction(novoTamanho, token)
            .then((result) => {
                toast.success(data ? "Tamanho atualizado com sucesso!" : "Tamanho criado com sucesso!");
                onSubmit(result);
                onClose();
            })
            .catch((err) => {
                console.error(err.response ? err.response.data : err.message);
                toast.error("Erro ao salvar o tamanho!");
            });
    }

    return (
        <div
            className={`modal fade ${openModal ? 'show' : ''}`}
            style={{ display: openModal ? 'block' : 'none' }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalTitleNotify"
            aria-hidden={!openModal}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">
                            {data ? "Alterar " : "Novo "} Tamanho
                        </p>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        className="form-control"
                                        id="descricao"
                                        name="descricao"
                                        type="text"
                                        placeholder="Informe a descrição"
                                        value={tamanho.descricao || ""}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Cancelar
                        </button>
                        <button type="button" className="btn btn-primary" onClick={onSubmitForm}>
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadSecaoTamanho;
