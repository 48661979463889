/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPedidoCompra } from "../../services/PedidoCompraService";
import { getItensPedidoCompra } from "../../services/ItensPedidoCompraService";
import { Link } from "react-router-dom";
import ItensPedidoCompraRow from "../../private/ItensPedidoCompra/ItensPedidoCompraRow";
import InformaPrecoModal from "./InformaPrecoModal";
import { getParceiroPorEmail } from "../../services/ParceiroService";

function PedidoCompraDetalhes() {
  const history = useNavigate();
  const { cod_pedido_compra } = useParams();
  const [codParceiro, setCodParceiro] = useState("");
  const [pedidoCompra, setPedidoCompra] = useState([]);
  const [itensPedidoCompra, setItensPedidoCompra] = useState([]);
  const [informaPreco, setInformaPreco] = useState({
    cod_itens_pedido_compra: 0,
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    const email = localStorage.getItem("sistemacemail");

    getParceiroPorEmail(email, token)
      .then((response) => {
        if (response) {
          setCodParceiro(response.cod_parceiro);
        } else {
          setCodParceiro(0);
        }
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  useEffect(() => {

    getPedidoCompra(cod_pedido_compra, token)
      .then((result) => {
        setPedidoCompra(result);

        getItensPedidoCompra(cod_pedido_compra, token)
          .then((result) => {
            result.rows.map((item) => {
              item.temPreco = false;

              return item.tb_preco_ipedido_compras.map((preco) => {
                if (preco.cod_pk_fornecedor == codParceiro) {
                  item.temPreco = true;
                }
              });
            });

            setItensPedidoCompra(result.rows);
          })
          .catch((err) => {
            console.error(err.response ? err.response.data : err.message);
          });
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, [codParceiro]);

  function onInformaPrecoClick(event) {
    const cod_itens_pedido_compra = event.target.id.replace("alterar", "");
    const itemPedidoCompra = itensPedidoCompra.find(
      (e) => e.cod_itens_pedido_compra == cod_itens_pedido_compra
    );
    setInformaPreco({ ...itemPedidoCompra });
  }

  function onModalSubmit(event) {
    setTimeout(function () {
      history.go(0);
    }, 1000);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Pedido de Compra</h2>
          </div>
          <Link
            to="/pedidocompra"
            className="d-flex align-items-center justify-content-center"
          >
            <svg
              className="icon icon-xs me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Voltar Pedido Compra
          </Link>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="numero_pedido" align="Left">
              Número
            </label>
            <input
              className="form-control"
              id="numero_pedido"
              type="text"
              placeholder="Informe a Número Pedido"
              defaultValue={pedidoCompra.numero_pedido}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="data" align="Left">
              Data
            </label>
            <input
              className="form-control"
              id="data"
              type="text"
              placeholder="Informe a Data"
              defaultValue={pedidoCompra.data}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="status" align="Left">
              Situação
            </label>
            <input
              className="form-control"
              id="status"
              type="text"
              placeholder="Situação"
              defaultValue={pedidoCompra.status}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="razao_fornecedor" align="Left">
              Fornecedor
            </label>
            <input
              className="form-control"
              id="razao_fornecedor"
              type="text"
              placeholder="Informe o fornecedor"
              defaultValue={pedidoCompra.razao_fornecedor}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Itens</h2>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Cód Prod</th>
                <th className="border-gray-200">Descrição</th>
                <th className="border-gray-200">Unidade</th>
                <th className="border-gray-200">Quantidade</th>
                <th className="border-gray-200">Preço Unitário</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {itensPedidoCompra.map((itemPedidoCompra) => (
                <ItensPedidoCompraRow
                  key={itemPedidoCompra.cod_itens_pedido_compra}
                  data={itemPedidoCompra}
                  onInformaPrecoClick={onInformaPrecoClick}
                />
              ))}
            </tbody>
          </table>
        </div>
        {/* <Footer /> */}
      </main>
      <InformaPrecoModal data={informaPreco} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default PedidoCompraDetalhes;
