import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const ITENS_PREVENDA_URL = `${API_URL}/itensprevenda/`;

export async function salvarItensPreVenda(cod_pre_venda, novoItensPreVenda, token) {

    const headers = { 'authorization': token };

    const response = await axios.post(`${ITENS_PREVENDA_URL}`, novoItensPreVenda, { headers });

    return response.data;
}

export async function getItensPreVenda(cod_pk_pre_venda, token) {

    const headers = { 'authorization': token };
    const response = await axios.get(`${ITENS_PREVENDA_URL}${cod_pk_pre_venda}`, { headers });
    return response.data;
}