import React, { useState } from "react";
import MeioPagamentoDetail from "./MeioPagamentoDetail";
import CadMeioPagamento from "./CadMeioPagamento";

const MeioPagamentoPage = () => {
  const [viewDetail, setViewDetail] = useState(false);
  const [consumer, setConsumer] = useState({});
  return (
    <>
      {viewDetail ? (
        <MeioPagamentoDetail
          data={consumer}
          setView={() => setViewDetail(false)}
        />
      ) : (
        <CadMeioPagamento
          setView={(consumer) => [setConsumer(consumer), setViewDetail(true)]}
        />
      )}
    </>
  );
};

export default MeioPagamentoPage;
