// import axios from "./BaseService";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function doLogin(email, senha, cod_org) {
  const loginUrl = `${API_URL}/login`;
  const response = await axios.post(loginUrl, { email, senha, cod_org });
  return response;
}

export async function doLoginFornecedor(email, senha, cod_org) {
  const loginUrl = `${API_URL}/loginfornecedor`;
  const response = await axios.post(loginUrl, { email, senha, cod_org });
  return response;
}

export async function doLogout(token) {
  const logoutUrl = `${API_URL}/logout`;
  const headers = { authorization: token };
  const response = await axios.post(logoutUrl, {}, { headers });
  return response;
}

export async function getCliente(cliente) {
  const url = `${API_URL}/cliente/${cliente}`
  try {
    const codOrg = await axios.get(url)
    return codOrg.data.cod_org

  } catch (error) {
    return error
  }
}
