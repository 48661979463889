import React, { useRef, useState, useEffect } from "react";
import { salvarParceiro } from "../../../services/ParceiroService";
import SwitchInput from "./SwitchInput";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadParceiroModal(props) {
  const [erro, setErro] = useState("");
  const [parceiro, setParceiro] = useState({
    tipo_parceiro: "C",
    tipo: "Pessoa Fisica",
    uf: "GO",
    situacao: "Ativo",
  });
  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setParceiro(props.data);
  }, [props.data]);

  function onSubmit(event) {
    
    parceiro.cod_org = localStorage.getItem("cod_org");
    
    salvarParceiro(parceiro.cod_parceiro, parceiro, token)
      .then((result) => {
        btnClose.current.click();
        if (props.onSubmit) props.onSubmit(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setErro(err.response ? err.response.data : err.message);
      });

    props.onSubmit(event);
  }

  function onInputChange(event) {
    setParceiro((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  return (
    <div
      className="modal fade bd-example-modal-lg"
      id="modalParceiro"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {props.data.cod_parceiro ? "Alterar " : "Nova "}Parceiro
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-parceiro-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-parceiro"
                  type="button"
                  role="tab"
                  aria-controls="nav-parceiro"
                  aria-selected="true"
                >
                  Parceiro
                </button>
                <button
                  class="nav-link"
                  id="nav-pessoal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-pessoal"
                  type="button"
                  role="tab"
                  aria-controls="nav-pessoal"
                  aria-selected="false"
                >
                  Dados Pessoal
                </button>
                <button
                  class="nav-link"
                  id="nav-comp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-comp"
                  type="button"
                  role="tab"
                  aria-controls="nav-comp"
                  aria-selected="false"
                >
                  Dados Complementares
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-parceiro"
                role="tabpanel"
                aria-labelledby="nav-parceiro-tab"
              >
                <br></br>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <label htmlFor="tipo_parceiro" align="Left">
                        Parceiro
                      </label>
                      <select
                        className="form-control"
                        value={parceiro.tipo_parceiro}
                        name="tipo_parceiro"
                        id="tipo_parceiro"
                        onChange={onInputChange}
                      >
                        <option value="C">Cliente</option>
                        <option value="F">Fornecedor</option>
                      </select>
                    </div>
                    <div className="col-md-5 mb-3">
                      <label htmlFor="tipo" align="Left">
                        Tipo
                      </label>
                      <select
                        className="form-control"
                        value={parceiro.tipo}
                        name="tipo"
                        id="tipo"
                        onChange={onInputChange}
                      >
                        <option value="Pessoa Fisica">Pessoa Fisica</option>
                        <option value="Pessoa Juridica">Pessoa Juridica</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <div className="form-group">
                        <label htmlFor="tipo" align="Left">
                          Situação
                        </label>
                        <SwitchInput
                          id="situacao"
                          text={parceiro.situacao}
                          onChange={onInputChange}
                          isChecked={parceiro.situacao}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="razao_social" align="Left">
                        Razão Social
                      </label>
                      <input
                        className="form-control"
                        id="razao_social"
                        type="text"
                        placeholder="Informe a Razão Social"
                        defaultValue={parceiro.razao_social}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="nome_fantasia" align="Left">
                        Nome Fantasia
                      </label>
                      <input
                        className="form-control"
                        id="nome_fantasia"
                        type="text"
                        placeholder="Informe a Nome Fantasia"
                        defaultValue={parceiro.nome_fantasia}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="logradouro" align="Left">
                        Logradouro
                      </label>
                      <input
                        className="form-control"
                        id="logradouro"
                        type="text"
                        placeholder="Informe a Logradouro"
                        defaultValue={parceiro.logradouro}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="numero" align="Left">
                        Numero
                      </label>
                      <input
                        className="form-control"
                        id="numero"
                        type="text"
                        placeholder="Numero"
                        defaultValue={parceiro.numero}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="bairro" align="Left">
                        Bairro
                      </label>
                      <input
                        className="form-control"
                        id="bairro"
                        type="text"
                        placeholder="Informe a Bairro"
                        defaultValue={parceiro.bairro}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cidade" align="Left">
                        Cidade
                      </label>
                      <input
                        className="form-control"
                        id="cidade"
                        type="text"
                        placeholder="Informe a Cidade"
                        defaultValue={parceiro.cidade}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="estado" align="Left">
                        Estado
                      </label>
                      <select
                        className="form-control"
                        value={parceiro.uf}
                        name="uf"
                        id="uf"
                        onChange={onInputChange}
                      >
                        <option value="AC">AC</option>
                        <option value="AM">AM</option>
                        <option value="RR">RR</option>
                        <option value="PA">PA</option>
                        <option value="AP">AP</option>
                        <option value="TO">TO</option>
                        <option value="MA">MA</option>
                        <option value="PI">PI</option>
                        <option value="CE">CE</option>
                        <option value="RN">RN</option>
                        <option value="PB">PB</option>
                        <option value="PE">PE</option>
                        <option value="AL">AL</option>
                        <option value="SE">SE</option>
                        <option value="BA">BA</option>
                        <option value="MG">MG</option>
                        <option value="ES">ES</option>
                        <option value="RJ">RJ</option>
                        <option value="SP">SP</option>
                        <option value="RR">PR</option>
                        <option value="SC">SC</option>
                        <option value="RS">RS</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="GO">GO</option>
                        <option value="DF">DF</option>
                      </select>
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_municipio" align="Left">
                        Código do Municipio
                      </label>
                      <input
                        className="form-control"
                        id="cod_municipio"
                        type="text"
                        placeholder="Informe o Código do Municipio"
                        defaultValue={parceiro.cod_municipio}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cep" align="Left">
                        Cep
                      </label>
                      <input
                        className="form-control"
                        id="cep"
                        type="text"
                        placeholder="Informe o Cep"
                        defaultValue={parceiro.cep}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cnpj" align="Left">
                        Cnpj
                      </label>
                      <input
                        className="form-control"
                        id="cnpj"
                        type="text"
                        placeholder="Informe o CNPJ"
                        defaultValue={parceiro.cnpj}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="inscricao_estadual" align="Left">
                        Inscrição Estadual
                      </label>
                      <input
                        className="form-control"
                        id="cnpj"
                        type="text"
                        placeholder="Informe o Inscrição Estadual"
                        defaultValue={parceiro.inscricao_estadual}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="telefone_residencial" align="Left">
                        Telefone
                      </label>
                      <input
                        className="form-control"
                        id="telefone_residencial"
                        type="text"
                        placeholder="Informe o Telefone"
                        defaultValue={parceiro.telefone_residencial}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-pessoal"
                role="tabpanel"
                aria-labelledby="nav-pessoal-tab"
              >
                <br></br>
                Dados Pessoais
              </div>
              <div
                class="tab-pane fade"
                id="nav-comp"
                role="tabpanel"
                aria-labelledby="nav-comp-tab"
              >
                <br></br>
                Dados Complementares
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {erro ? (
              <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <button
              ref={btnSalvar}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onSubmit}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadParceiroModal;
