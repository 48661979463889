import React from "react";
import { Modal, Box } from "@mui/material";
import "./ObservationModal.scss";

const ObservationModal = ({ ...props }) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="observation-modal">
        <main className="">
          <div>
            <p className="title">Observação do produto</p>
            <textarea
              name=""
              id=""
              rows="10"
              maxLength={100}
              placeholder="Ex: Tirar cebola e etc..."
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={(e) => props.setObservationText(e.target.value)}
            ></textarea>
          </div>
          <div className="box-button">
            <button className="back" onClick={() => props.setFalseModal()}>
              Cancelar
            </button>
            <button className="save" onClick={() => props.setFalseModal()}>
              Salvar
            </button>
          </div>
        </main>
      </Box>
    </Modal>
  );
};

export default ObservationModal;
